import React, {useState, useEffect, useContext} from 'react';
import {ApiContext} from '../App';
import YouTube, { YouTubeProps } from 'react-youtube';
import '../css/components/Section00.css';

const Section00: React.FC = () => {
        const apiUrl = useContext(ApiContext);
        const [mediaType, setMediaType] = useState<string>('');
        const [videoSrc, setVideoSrc] = useState<string>('');

        // const isMobileDevice = () => {
        //     return /Mobi|Android|iPhone|iPad|iPod/i.test(navigator.userAgent);
        // };

        const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

        useEffect(() => {
            const handleResize = () => {
                const isNowMobile = window.innerWidth <= 780;

                if (isNowMobile !== isMobile) {
                    // 모바일/PC 상태가 변경될 때 API 호출
                    setIsMobile(isNowMobile);
                }
            };

            const callApi = async ({deviceType}: { deviceType: any }) => {
                try {
                    const endpoint = deviceType === 'Mobile'
                        ? `${apiUrl}/api/main/thumbnail/mobile`
                        : `${apiUrl}/api/main/thumbnail/pc`;

                    const response = await fetch(endpoint);

                    if (!response.ok) {
                        console.error('Network error:', response.statusText);
                        return;
                    }

                    const result = await response.json();

                    // console.log('result :', result);
                    if (result.code == 200 && result.data) {
                        //iframe_link.src =  `${videoSource}?autoplay=1&loop=1&mute=1&playlist=${videoSource.split('/')[4]}`;;
                        const videoSource = result.data.mediaUrl;

                        // mediaType
                        // mediaUrl
                        setMediaType(result.data.mediaType);
                        setVideoSrc(result.data.mediaType === 'file' ? result.data.videoFilePath :  `${videoSource}?autoplay=1&loop=1&mute=1&playlist=${videoSource.split('/')[4]}`);
                    } else {
                        console.error('Failed to fetch thumbnail images:', result.message);
                    }
                } catch (error) {
                    console.error('Error fetching thumbnail images:', error);
                }
            };

            // 초기 상태에 따라 API 호출
            callApi({deviceType: isMobile ? "Mobile" : "PC"});

            window.addEventListener("resize", handleResize);

            // cleanup
            return () => {
                window.removeEventListener("resize", handleResize);
            };
        }, [isMobile]);

        // useEffect(() => {
        //     const fetchBackgroundImages = async () => {
        //         try {
        //             const endpoint = isMobileDevice()
        //                 ? `${apiUrl}/api/main/thumbnail/mobile`
        //                 : `${apiUrl}/api/main/thumbnail/pc`;
        //
        //             const response = await fetch(endpoint);
        //
        //             if (!response.ok) {
        //                 console.error('Network error:', response.statusText);
        //                 return;
        //             }
        //
        //             const result = await response.json();
        //             console.log('API Response:', result);
        //
        //             if (result.code == 200 && result.data) {
        //                 setMediaType(result.data.mainOption);
        //                 setVideoSrc(result.data.videoFilePath);
        //                 console.log('mediaType:', result.data.mainOption);
        //                 console.log('videoFilePath:', result.data.videoFilePath);
        //             } else {
        //                 console.error('Failed to fetch thumbnail images:', result.message);
        //             }
        //         } catch (error) {
        //             console.error('Error fetching thumbnail images:', error);
        //         }
        //     };
        //
        //     fetchBackgroundImages();
        // }, [apiUrl]);

    const onPlayerReady: YouTubeProps['onReady'] = (event) => {
        // access to player in all event handlers via event.target
        event.target.pauseVideo();
    }
    const options = {
        height: "10px",
        width: "10px",
        playerVars: {
            autoplay: 1, //자동재생 O
            mute: 1,
            loop: 1,
            playlist: 'dHXbZQZDnWM',
            rel: 0, //관련 동영상 표시하지 않음 (근데 별로 쓸모 없는듯..)
            modestbranding: 1, // 컨트롤 바에 youtube 로고를 표시하지 않음
        },
    };
        return (
            <section id="section00">
                <a href="#prologue" className="m_menus">
                    <img src="/image/scroll_with_text.png" alt="Down"/>
                </a>
                <div className="video_box">
                    {videoSrc && (mediaType === 'file' ? (
                        <video autoPlay muted loop className="videoPlayer">
                            <source src={videoSrc} type="video/mp4" />
                            Your browser does not support the video tag.
                        </video>
                    ) : (
                        <iframe
                            // id="iframe_link"
                            width="80%"
                            height="80%"
                            src={videoSrc}
                            title="YouTube video player"
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            style={{pointerEvents: 'none'}}
                        ></iframe>
                    ))}
                </div>
            </section>
        );
    };

export default Section00;
