import React, {useContext, useEffect, useState, useRef} from 'react';
import '../payment.css';
import {useNavigate, useNavigationType, useLocation} from "react-router-dom";
const PaymentSuccess: React.FC = () => {
    // const navigate = useNavigate();

    // const navigate = useNavigate();
    // const navigationType = useNavigationType();
    //
    // useEffect(() => {
    //     console.log('navigate :', navigate);
    //     console.log('navigationType :', navigationType);
    //     if(navigationType === 'POP') {
    //         // navigate('/');
    //     }
    // }, [navigate, navigationType]);
    const navigate = useNavigate();
    //
    // useEffect(() => {
    //
    //     // window.addEventListener("popstate", function(event) {
    //     //     alert("뒤로가기 버튼이 클릭되었습니다!");
    //     //     navigate("/", { replace: true }); // 뒤로 가기 시 메인으로 이동
    //     //
    //     // });
    //
    //     window.addEventListener("popstate", function(event) {
    //         alert("뒤로가기 버튼이 클릭되었습니다!");
    //     });
    //     // return () => {
    //     //     // 컴포넌트 언마운트 시 이벤트 리스너 제거
    //     //     window.removeEventListener("popstate", function(event) {
    //     //         alert("뒤로가기 버튼이 클릭되었습니다!");
    //     //         navigate("/", { replace: true }); // 뒤로 가기 시 메인으로 이동
    //     //     });
    //     // };
    // }, []);


    return (
        <div className="p-bg">
            <div className="payment">
                <div className="p-wrap success">
                    <img src="/image/success.svg" alt="success" />
                        <p>Payment Success</p>
                        <p>Your Payment was successful!</p>
                        <div className="btn_container">
                            <button onClick={() => {
                                navigate("/")
                            }}>Back to Main</button>
                            {/*<button >Go to Mypage</button>*/}
                        </div>
                </div>
            </div>
        </div>
    );
};

export default PaymentSuccess;

// 빈 export 구문 추가
export {};
