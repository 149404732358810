import React, {useContext, useEffect, useState} from 'react';
import '../payment.css';
import '../css/pages/MyPage.css';
import {ApiContext} from "../App";
import {useWallet} from "@aptos-labs/wallet-adapter-react";
import Header from "../components/Header";
import {useNavigate} from "react-router-dom";

interface DataItem {
    index: number;
    orderNo: string;
    seqNo: number;
    productName: string;
    amount: number;
    quantity: string;
    regDate: string;
    recvName: string;
    recvPhone: string;
    recvEmail: string;
    recvAddress: string;
    status: string;
    seeMore: false;
    deliveryStatus: string;
}

const PurchaseHistory: React.FC = () => {
    const apiUrl = useContext(ApiContext);
    const [totalCount, setTotalCount] = useState(0); // 현재
    const [currentPage, setCurrentPage] = useState(1); // 현재 페이지
    const [list, setList] = useState<DataItem[]>([]); // 데이터 리스트 (배열로 초기화)
    const [error, setError] = useState<string | null>(null); // Error state
    const rowsPerPage = 5; // 페이지당 표시할 데이터 개수
    const storedAccount = localStorage.getItem('@aptos-connect/connectedAccount');
    const {account} = useWallet();
    const [more, setMore] = useState(false); // 현재
    const navigate = useNavigate();

    useEffect(() => {

        console.log(storedAccount);
        fetchData();
    }, [apiUrl, currentPage, account?.address]); // Add apiUrl to dependency array

    const fetchData = async () => {
        try {

            console.log('123444');
            const response = await fetch(`${apiUrl}/api/payment/pay-list?pageNum=${currentPage}&itemCount=5&walletAddr=${account?.address}`);
            const result = await response.json();
            console.log('result: ', result);
            // Ensure the result is an object with a 'data' property containing an array
            if (result && Array.isArray(result.data[0]?.getPaymentListResponse)) {

                const dataWithIndex = result.data[0]?.getPaymentListResponse.map((item: any, index: number) => ({
                    ...item,
                    // index: index + 1, // Adding 1 to the index to make it human-readable (starting from 1)
                    seeMore: false,
                }));

                console.log('dataWithIndex: ', dataWithIndex);
                setTotalCount(result.data[0]?.totalCount);
                setList(dataWithIndex);
            } else {
                setError('Invalid data format received.');
                console.error('Expected an object with a "data" array but got:', result);
            }

        } catch (error) {
            setError('Error fetching data. Please try again later.');
            console.error('Error fetching data:', error);
        }
    };

    useEffect(() => {
        console.log('Updated list:', list);
    }, [list]);

    // 데이터 페이징
    const indexOfLastRow = currentPage * rowsPerPage;
    const indexOfFirstRow = indexOfLastRow - rowsPerPage;
    const currentRows = list.slice(indexOfFirstRow, indexOfLastRow);

    // 총 페이지 계산
    const totalPages = Math.ceil(totalCount / rowsPerPage);

    const handlePageChange = (page: number) => {
        setCurrentPage(page);
    };
    const handleBack = () => {
        navigate(-1); // 히스토리 스택에서 한 단계 뒤로 이동
    };

    const PayCancel = async (seqNo: any) => {
        //     취소요청 api (POST)
        // 결제완료(SUCCESS) -> 취소요청(CANCEL_REQUESTED) 상태값 변경 api
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

        const urlencoded = new URLSearchParams();
        urlencoded.append("seqNo", seqNo);

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: urlencoded,
            redirect: "follow"
        };

        // @ts-ignore
        fetch("https://api.l-and.shop/api/payment/cancel-request", requestOptions)
            .then((response) => response.text())
            .then((result) => {
                fetchData();
            })
            .catch((error) => console.error(error));
    };

    const PayCancel2 = async (seqNo: any) => {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

        const urlencoded = new URLSearchParams();
        urlencoded.append("seqNo", seqNo);

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: urlencoded,
            redirect: "follow"
        };

        // @ts-ignore
        fetch("https://api.l-and.shop/api/payment/cancel-revert", requestOptions)
            .then((response) => response.text())
            .then((result) => {
                console.log(result);
                fetchData();
            })
            .catch((error) => console.error(error));
    };

    const ViewSeeMore = (orderNo: string) => {
        alert(orderNo);


        // @ts-ignore
        setList((prevList) =>
            prevList.map((order) =>
                order.orderNo === orderNo
                    ? { ...order, seeMore: true }
                    : order
            )
        );
        // setList(updatedState);
    }

    console.log(list);
    return (
        <>
            <div style={{paddingBottom: '5%'}}>
                <Header/>
            </div>
            <div className="mypageModal fade show" style={{paddingRight: '17px;'}}
            >
                <div>
                    <div style={styles.overflowVisible}>
                        {/*<button data-dismiss="modal" className="modal-close-btn w-ba none_bg"></button>*/}
                        <div className="w-100">
                            <div className="mt-md-4 mb-4 mt-2">
                                <div className="text-left mb-4">
                                    <h4 className="mb-0 font-bold hisFont">Purchase history</h4>
                                </div>
                                <div className={"more_container"} style={{paddingBottom: '10px'}}>
                                    <button className={"clean-button"} onClick={() => {
                                        setMore(!more)
                                    }}>{more ? 'Close' : 'More'}</button>
                                </div>
                                <div className="form-container"
                                     style={{width: '100%', overflowX: 'auto', whiteSpace: 'nowrap'}}>
                                    <table id="mypageList">
                                        <thead>
                                        <tr style={{height: '50px'}}>
                                            <th>Order Number</th>
                                            <th>Product Name</th>
                                            <th>Price</th>
                                            <th>Quantity</th>
                                            <th>Purchase Date</th>
                                            <th>Recipient</th>
                                            <th style={{display: more ? "" : "none"}}>Phone Number</th>
                                            <th style={{display: more ? "" : "none"}}>Email</th>
                                            <th style={{display: more ? "" : "none"}}>Address</th>
                                            <th>Status</th>
                                            <th>Delivery Status</th>
                                        </tr>
                                        </thead>
                                        <tbody id="tableBody">
                                        {list.length > 0 ? (
                                            list.map((row, index) => (
                                                <tr key={index}> {/* Use r, ow.id instead of index for a unique key */}
                                                    <td style={styles.td}>{row.orderNo}</td>
                                                    <td style={styles.td}>{row.productName}</td>
                                                    <td style={styles.td}>{row.amount?.toLocaleString()}</td>
                                                    <td style={styles.td}>{row.quantity}</td>
                                                    <td style={styles.td}>{row.regDate}</td>
                                                    <td style={styles.td}>{row.recvName}</td>
                                                    <td style={Object.assign({}, styles.td, {display: more ? "" : "none"})}>{row.recvPhone}</td>
                                                    <td style={Object.assign({}, styles.td, {display: more ? "" : "none"})}>{row.recvEmail}</td>
                                                    <td style={Object.assign({}, styles.td, {display: more ? "" : "none"})}>{row.recvAddress}</td>
                                                    <td style={styles.td}>
                                                        {row.status === 'SUCCESS' ? (
                                                            <button className="clean-button" onClick={() => {
                                                                PayCancel(row.seqNo);
                                                            }}>Cancel</button>
                                                        ) : row.status === 'PENDING' ? (
                                                            `Confirm`
                                                        ) : row.status === 'CANCEL_REQUESTED' ? (
                                                            <button className="clean-button" onClick={() => {
                                                                PayCancel2(row.seqNo);
                                                            }}>Applying for cancellation</button>
                                                        ) : (
                                                            row.status
                                                        )}
                                                    </td>
                                                    <td style={styles.td}>{row.deliveryStatus}</td>

                                                </tr>
                                            ))) : <></>}
                                        </tbody>
                                    </table>
                                    {/* 페이징 버튼 */}
                                    <div style={styles.pagination}>
                                        {Array.from({length: totalPages}, (_, index) => (
                                            <button
                                                key={index}
                                                onClick={() => handlePageChange(index + 1)}
                                                style={{
                                                    ...styles.pageButton,
                                                    backgroundColor: currentPage === index + 1 ? '#007bff' : '#f4f4f4',
                                                    color: currentPage === index + 1 ? '#fff' : '#000',
                                                }}
                                            >
                                                {index + 1}
                                            </button>
                                        ))}
                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <div className="wrap-btn">
                    <button className={"back-button"} type="reset" onClick={() => {
                        handleBack();
                    }}>Close
                    </button>
                </div>
            </div>
        </>
    );
};

const styles = {
    table: {
        width: '100%',
        borderCollapse: 'collapse' as const,
        margin: '20px 0',
    },
    th: {
        border: '1px solid #ddd',
        padding: '8px',
        backgroundColor: '#f4f4f4',
        textAlign: 'left' as const,
        color: '#000',
    },
    td: {
        border: '1px solid #ddd',
        padding: '8px',
        color: '#000',
    },
    pagination: {
        marginTop: '10px',
        textAlign: 'center' as const,
        paddingBottom: '10px'
    },
    pageButton: {
        margin: '0 5px',
        padding: '8px 12px',
        border: '1px solid #ddd',
        borderRadius: '4px',
        cursor: 'pointer',
        outline: 'none',
    },

    overflowVisible: {
        overflow: 'visible !important'
    },
};

export default PurchaseHistory;