import React, { createContext, useContext } from "react";

// Context 생성
interface FullPageContextType {
    goToSection: (index: number) => void;
}

// 초기값은 null
const FullPageContext = createContext<FullPageContextType | null>(null);

// 커스텀 훅 생성
export const useFullPageContext = () => {
    const context = useContext(FullPageContext);
    if (!context) {
        throw new Error("useFullPageContext must be used within a FullPageProvider");
    }
    return context;
};

// FullPageContext와 useFullPageContext를 export합니다.
export { FullPageContext };
