// eslint-disable-next-line no-unused-vars
"use client";
import React, {useState, useEffect, useCallback, useContext} from 'react';
import {useWeb3React} from "@web3-react/core";
import ConnectWalletWithGoogle from "../web3/ConnectWalletWithGoogle";
import {WalletSelector} from "../web3/WalletSelector";
import AppleLogin from 'react-apple-login';
import {jwtDecode} from "jwt-decode";
import {InjectedConnector} from "@web3-react/injected-connector";
import {useWallet} from '@aptos-labs/wallet-adapter-react';
import PurchaseHistory from "../pages/PurchaseHistory";
import Modal from "react-modal";
import {useNavigate} from "react-router-dom";
import SocialList from './SocialList';
import {ApiContext} from "../App";
import {useFullPageContext} from "../FullPageContext";

const injected = new InjectedConnector({supportedChainIds: [1, 3, 4, 5, 42]});

interface ConnectWalletProps {
    isOpen: boolean;
    onClose: () => void;
    address: string | null | undefined;
}

interface HistoryProps {
    isOpen: boolean;
    onClose: () => void;
}

interface HeaderProps {
    isHidden?: boolean
}

// @ts-ignore
const Header: React.FC<HeaderProps> = ({isHidden}: { isHidden: boolean }) => {
    const apiUrl = useContext(ApiContext);
    const navigate = useNavigate();
    const [isWalletOpen, setIsWalletOpen] = useState(false);
    const [isHistoryOpen, setIsHistoryOpen] = useState(false);
    const [isSocialOpen, setIsSocialOpen] = useState(false);
    const [btnHidden, setBtnHidden] = useState(false);
    const {goToSection} = useFullPageContext(); // goToSection 가져오기
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 780);
    const openWallet = () => setIsWalletOpen(true);
    // const closeWallet = () => setIsWalletOpen(false);
    const closeWallet = () => {

        const currentPath = window.location.pathname;
        // 루트와 ProductDetail이 포함된 경로를 제외한 경우 메인 페이지로 이동
        if (
            currentPath !== '/' ||
            !currentPath.includes('ProductBuy') ||
            !currentPath.includes('ProductDetail') // 'ProductDetail'을 포함하지 않는 경우
        ) {
            navigate('/'); // 메인 페이지로 이동
        }

        setIsWalletOpen(false); // 지갑 닫기

    };
    //social
    const socialOpen = () => setIsSocialOpen(true);
    const closeSocial = () => setIsSocialOpen(false);

    const tokenValue = localStorage.getItem('account');
    const [token, setToken] = useState(false);

    const {connect, disconnect, account, connected, wallets} = useWallet();
    const [visibleSlide, setVisibleSlide] = useState<boolean>(true);

    function isPC() {
        const userAgent = navigator.userAgent;

        // 모바일 기기에 해당하는 키워드
        const mobileKeywords = [
            "Android", "iPhone", "iPad", "iPod", "BlackBerry", "Windows Phone", "webOS"
        ];

        // User Agent에 모바일 키워드가 포함되어 있으면 모바일로 간주
        return !mobileKeywords.some(keyword => userAgent.includes(keyword));
    }

    useEffect(() => {
        const handleResize = () => {
            const isNowMobile = window.innerWidth <= 780;

            if (isNowMobile !== isMobile) {
                // 모바일/PC 상태가 변경될 때 API 호출
                setIsMobile(isNowMobile);
            }
        };

        window.addEventListener("resize", handleResize);

        // cleanup
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, [isMobile]);

    useEffect(() => {
        const _isPC = isPC();
        console.log(_isPC);
        const pathname = window.location.pathname;
        if (pathname === '/pages/Payment') {
            setBtnHidden(true);
        } else {
            setBtnHidden(false);
        }
    }, [window.location.pathname]);


    useEffect(() => {
        console.log("dev 개발서버");
        if (tokenValue === null) {
            setToken(false);
        } else {
            setToken(true);
        }
    }, [tokenValue]);

    useEffect(() => {
        if (!account?.address) {
            console.log("No account found");
        }
        localStorage.setItem('account', account?.address as string);
    }, [account]);

    useEffect(() => {
        const titleButton = document.querySelector(".wallet-button span");

        // titleButton이 존재하면 텍스트를 제거
        if (titleButton) {
            titleButton.textContent = "";
        }

    }, []);

    useEffect(() => {
        const fetchSlides = async () => {
            try {
                const response = await fetch(`${apiUrl}/api/notice/list`);
                const result = await response.json();

                console.log('result :', result);
                if (result.code === 200) {

                    if (result.data.length > 0) {
                        setVisibleSlide(true);
                    } else {
                        setVisibleSlide(false);
                    }
                } else {
                    console.error('Error fetching slides:', result.message);
                    setVisibleSlide(false);
                }
            } catch (error) {
                console.error('Failed to fetch slides:', error);
                setVisibleSlide(false);
            }
        };

        fetchSlides();
    }, [apiUrl]);

    const ToSection = (index: number, section: string) => {
        if (window.location.href.includes('pages')) {
            // 상태를 전달하면서 네비게이션
            sessionStorage.setItem('s_index', `${index}`); // 'key'에 'value' 값을 담음

            navigate("/");
        } else {
            goToSection(index)
        }
    };

    return (
        <div>
            <header className={`header-scroll ${isHidden ? "opacity 0.3s ease-out" : ""}`}>
                {/*<header className={`header-scroll`} style={{opacity: isHidden ? '' : '0.3s ease-out'}}>*/}
                <div className="logo">
                    <a href="/">
                        <img src="/image/pudgy_logo3.png" alt="Logo"/>
                    </a>
                </div>
                <div className="gnb">
                    <ul>
                        {isMobile ?
                            <>
                                <li><a href="javascript: void(0);" onClick={() => ToSection(0, 'prologue')}>PROLOGUE</a></li>
                                <li><a className={!visibleSlide ? 'visibleFalse' : ``} href="javascript: void(0);"
                                       onClick={() => ToSection(1, 'shop')}>SHOP</a></li>
                            </>
                            :
                            <>
                                <li><a href="javascript: void(0);" onClick={() => ToSection(0, 'prologue')}>PROLOGUE</a></li>
                                <li><a className={!visibleSlide ? 'visibleFalse' : ``} href="javascript: void(0);"
                                       onClick={() => ToSection(1, 'shop')}>SHOP</a></li>
                            </>

                        }
                        <li><a href="javascript: void(0);" onClick={() => ToSection(2, 'land')}>L.AND</a></li>
                        {/*<li><a href="javascript: void(0);" onClick={() => ToSection(4, 'project')}>PROJECT</a></li>*/}
                    </ul>
                </div>
                {account?.address ? (
                    <div className="logout">
                        <button type="button" onClick={socialOpen} className="X-Social"></button>
                        <button type="button" onClick={() => {
                            navigate('/pages/PaymentDetails');
                        }} className="mypage"></button>
                        <button type="button" onClick={openWallet} className="logoutBtn"></button>
                    </div>
                ) : (
                    <div className="login">
                        <button type="button" onClick={socialOpen} className="X-Social"></button>
                        <WalletSelector/>
                    </div>
                )}

                <SocialList isModalOpen={isSocialOpen} closeModal={closeSocial}/>
                <ConnectWallet isOpen={isWalletOpen} onClose={closeWallet} address={account?.address}/>
            </header>
        </div>
    );
}


const ConnectWallet: React.FC<ConnectWalletProps> = ({isOpen, onClose, address}) => {

    const {disconnect} = useWallet();

    const handleDisconnect = () => {
        disconnect(); // disconnect 함수 호출로 지갑 연결 해제
        localStorage.removeItem('MetaMask');
        onClose();
    };

    return (
        <div className="header-modal-overlay">
            {isOpen && (
                <>
                    <div onClick={onClose}></div>

                    <div className="eventModal " tabIndex={-1} role="dialog" aria-modal="true">
                        <div className="modal-dialog modal-dialog-centered" role="document">
                            <div className="modal-content min-h-auto bg_white posi-r"
                                 style={{border: '3px solid #00142D'}}>
                                <button onClick={onClose} className="modal-close-btn2 w-ba logoutClose">
                                    <img src="/image/close.png" alt="Close"/>
                                </button>
                                <div className="wallbody loginPage logoutPage">
                                    <div className="modal-body">
                                        <center><img src="/image/logoutText.png" alt="Connect Wallet"/></center>
                                        <p className="wall_ment outText">
                                            Are you sure you want to log out?
                                        </p>
                                        <p className="wall_ment w_address">
                                            <span>{address}</span>
                                        </p>
                                        <div className="wrap-btn outBtn">
                                            <button onClick={onClose}>Cancel</button>
                                            <button onClick={handleDisconnect}>Confirm</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </div>
    )
};

export default Header;
