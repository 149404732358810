import React, {useState, useEffect} from "react";
import '../css/components/Section05.css';
import PurchaseHistory from "../pages/PurchaseHistory";
import Modal from "react-modal";
import TermsOfUse from "../pages/TermsOfUse";
import PrivacyPolicy from "../pages/PrivacyPolicy";

const Section05: React.FC = () => {
    const [isTermsVisible, setIsTermsVisible] = useState<boolean>(false);
    const [isPrivacyVisible, setIsPrivacyVisible] = useState<boolean>(false);

    const openTermsVisible = () => setIsTermsVisible(true);
    const closeTermsVisible = () => setIsTermsVisible(false);

    const openPrivacyVisible = () => setIsPrivacyVisible(true);
    const closePrivacyVisible = () => setIsPrivacyVisible(false);


    return (
        <section id="section05">
            <article className="footer">
                <div className="logo_btn">
                    <img src="/image/pudgy_logo3.png" alt="Footer Logo"/>
                </div>
                <div className="copy">
                    <Modal  //Terms
                        isOpen={isTermsVisible}
                        onRequestClose={closeTermsVisible} // 배경 클릭이나 ESC 키로 모달 닫기
                        contentLabel="Modal"
                        style={{
                            overlay: {
                                overflow: 'scroll',
                                zIndex: '99999',
                            },
                            content: {
                                top: "50%",
                                left: "50%",
                                right: "auto",
                                bottom: "auto",
                                marginRight: "-50%",
                                transform: "translate(-50%, -50%)",
                                padding: "20px",
                                borderRadius: "10px",
                                height: "70%",
                                maxHeight: "90vh", // 화면 높이에 맞추어 제한
                                zIndex: "1",
                                width: "90%",
                                background: "rgba(30, 30, 30, 0.8)"
                            },
                        }}
                    >
                        <>
                            <button className="m-close-btn" data-modal="termsofUseModal" id="closeModalButton"
                                    onClick={() => {
                                        closeTermsVisible();
                                    }}>
                                <img src="/image/close_modal.png"/>
                            </button>
                            <TermsOfUse closeModal={closeTermsVisible}/>
                        </>
                    </Modal>
                    <Modal //privacy
                        isOpen={isPrivacyVisible}
                        onRequestClose={closePrivacyVisible} // 배경 클릭이나 ESC 키로 모달 닫기
                        contentLabel="Modal"
                        style={{
                            overlay: {
                                zIndex: '99999',
                            },
                            content: {
                                top: "50%",
                                left: "50%",
                                right: "auto",
                                bottom: "auto",
                                marginRight: "-50%",
                                transform: "translate(-50%, -50%)",
                                padding: "20px",
                                borderRadius: "10px",
                                height: "70%",
                                maxHeight: "90vh", // 화면 높이에 맞추어 제한
                                zIndex: "1",
                                width: "90%",
                                background: "rgba(30, 30, 30, 0.8)"
                            },
                        }}
                    >
                        <>
                            <button className="m-close-btn" data-modal="termsofUseModal" id="closeModalButton"
                                    onClick={() => {
                                        closePrivacyVisible();
                                    }}>
                                <img src="/image/close_modal.png"/>
                            </button>
                            <PrivacyPolicy closeModal={closePrivacyVisible}/>
                        </>
                    </Modal>
                    <a onClick={openTermsVisible}>Terms of Use</a>ㆍ<a onClick={openPrivacyVisible}>Privacy Policy</a>
                    <p>
                        Daehong Communications Co., Ltd. (821-88-01030, CEO Hong Seong-hyeon, 10 Tongil-ro, Jung-gu,
                        Seoul)
                        <br/>
                        Mail order number 2018-Seoul Jung-gu-0681
                        <br/>
                        COPYRIGHT ⓒ 2024 DAEHONG COMMUNICATIONS INC. ALL RIGHTS RESERVED
                    </p>
                </div>
            </article>
        </section>
    );
};


export default Section05;
