import React, {useEffect, useState, useRef, useContext} from "react";
import {Navigate, useLocation} from "react-router-dom";
import {useWallet} from "@aptos-labs/wallet-adapter-react";
import {ApiContext} from "./App";

interface ProtectedRouteProps {
    children: React.ReactNode;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children }) => {
    const apiUrl = useContext(ApiContext);
    const {state} = useLocation();
    const [isAllowed, setIsAllowed] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(true);
    const {account} = useWallet();


    useEffect(() => {
        // 로컬스토리지에서 연결된 계정 확인
        const connectedAccount = localStorage.getItem(
            "@aptos-connect/connectedAccount"
        );
        if (connectedAccount) {
            setIsAllowed(true); // 계정이 있으면 페이지 접근 허용
        }
        setLoading(false); // 로딩 종료
    }, []);

    //페이지 빠져나오면 실행
    const location = useLocation();
    const hasLeftPage = useRef(true);

    useEffect(() => {
        const seqNo = localStorage.getItem('seqNo');
        // @ts-ignore
        const incrementedSeqNo = parseInt(seqNo, 10);
        console.log("Incremented seqNo: " + incrementedSeqNo);

        const handlePageChange = () => {
            if (hasLeftPage.current) {
                console.log("1 " + hasLeftPage.current);
                console.log("페이지에 진입함:", location.pathname);
                hasLeftPage.current = false; // 현재 페이지에 머물고 있다고 설정
            } else {
                console.log("2 " + hasLeftPage.current);
                console.log("페이지에서 빠져나옴:", location.pathname);

                // 로직 실행: API 호출 및 기타 처리
                const myHeaders = new Headers();
                myHeaders.append("Content-Type", "application/json");

                const raw = JSON.stringify({
                    "seqNo": incrementedSeqNo,
                    "productSeq": state?.product_seq,
                    "quantity": state?.quantity,
                    "walletAddr": account?.address || '',
                });

                const requestOptions = {
                    method: "POST",
                    headers: myHeaders,
                    body: raw,
                    redirect: "follow"
                };

                // @ts-ignore
                fetch(`${apiUrl}/api/payment/delete`, requestOptions)
                    .then((response) => response.text())
                    .then((result) => {
                        console.log('result22:', result);
                        const res = JSON.parse(result);
                        if (res.res.status === '0000') {
                            localStorage.setItem('seqNo', res.res.seqNo);
                            console.log('result22:', res);
                        }
                    })
                    .catch((error) => console.error(error));

                hasLeftPage.current = true; // 페이지를 떠났다고 설정
            }
        };

        // 페이지 변경 시 로직 실행
        handlePageChange();
    }, [location]);


    if (loading) {
        return <div>Loading...</div>; // 로딩 중 화면
    }

    if (!isAllowed) {
        return <Navigate to="/" replace />; // 계정 없으면 메인으로 리다이렉트
    }

    return <>{children}</>; // 페이지 접근 허용
};

export default ProtectedRoute;
