import React, {useState, useEffect, useContext, useRef, useCallback} from 'react';
import { useParams } from 'react-router-dom';
import Header from '../components/HeaderHidden';
import Section05 from '../components/Section05';
import '../App.css';
import { ApiContext } from '../App';

interface ProductData {
  seqNo: number;
  productName: string;
  category: string;
  description: string;
  content: string;
  listContentMobile: string;
  botContent: string;
  retailPrice: number;
  soldPrice: number;
  productStock: number;
  imageFilePath: string;
  subImg1Path: string;
  subImg2Path: string;
  subImg3Path: string;
  subImg4Path: string;
  subImg5Path: string;
}

const ProductDetail: React.FC = () => {
  const apiUrl = useContext(ApiContext);
  const { seqNo } = useParams<{ seqNo: string }>();
  const [productData, setProductData] = useState<ProductData | null>(null);
  const containerRef = useRef<HTMLDivElement>(null); // container 요소 참조 생성
  const [isScrollingDown, setIsScrollingDown] = useState(false);
  const [lastScrollY, setLastScrollY] = useState(0); // 마지막 스크롤 위치

  function isPC() {
    const userAgent = navigator.userAgent;

    // 모바일 기기에 해당하는 키워드
    const mobileKeywords = [
      "Android", "iPhone", "iPad", "iPod", "BlackBerry", "Windows Phone", "webOS"
    ];

    // User Agent에 모바일 키워드가 포함되어 있으면 모바일로 간주
    return !mobileKeywords.some(keyword => userAgent.includes(keyword));
  }

  useEffect(() => {
    const fetchProductData = async () => {
      try {
        // const response = await fetch(`${apiUrl}/api/product/detail/${seqNo}`);//detail
        const response = await fetch(`${apiUrl}/api/shop/detail/${seqNo}`);//detail
        const result = await response.json();

        if (result.code == 200) {
          setProductData(result.data);
        } else {
          console.error('Error fetching product data:', result.message);
        }
      } catch (error) {
        console.error('Failed to fetch product data:', error);
      }
    };

    fetchProductData();
  }, [seqNo]);

  const handleScroll = (event: React.WheelEvent) => {
    if (containerRef.current) {
      containerRef.current.scrollTop += event.deltaY;
    }

    // @ts-ignore
    if(event.deltaY == "-100" || event.deltaY == "-50"){
      setIsScrollingDown(false);
    }else { // @ts-ignore
      if(event.deltaY == "100" || event.deltaY == "50"){
        setIsScrollingDown(true);
      }
    }
  };

  const mobileScroll = useCallback(() => {
    const currentScrollY = window.scrollY;

    if (currentScrollY > lastScrollY && currentScrollY > 50) {
      setIsScrollingDown(true);
    } else {
      setIsScrollingDown(false);
    }

    setLastScrollY(currentScrollY); // 스크롤 위치 갱신
  }, [lastScrollY]);

  useEffect(() => {
    window.addEventListener("scroll", mobileScroll);

    return () => {
      window.removeEventListener("scroll", mobileScroll);
    };
  }, [mobileScroll]);

  // 마우스 휠 스크롤 이벤트 추가
  useEffect(() => {
    const handleWheel = (event: WheelEvent) => {
      const containerElement = containerRef.current;
      if (containerElement) {
        // scrollTop 값을 deltaY만큼 증가시킵니다.
        containerElement.scrollTop += event.deltaY;
      }
    };

    // containerRef가 null이 아닌 경우에만 이벤트 리스너를 추가
    const containerElement = containerRef.current;
    containerElement?.addEventListener('wheel', handleWheel);

    // 컴포넌트 언마운트 시 이벤트 리스너 제거
    return () => {
      containerElement?.removeEventListener('wheel', handleWheel);
    };
  }, []);

  return (
    <div className="product-detail" onWheel={handleScroll} ref={containerRef}>
      <Header isHidden={isScrollingDown}/>
      {/* <div className="wallet">
        <button className="event-link">
          <img src="/image/wallet.png" alt="Wallet" />
        </button>
      </div> */}

      {/* Belly Yellow Image Section */}
      <div className="belly-Y">
        {/*<div className="belly-yellow">*/}
        {/*  <img src="/image/bellyYellow.png" alt="Belly Yellow" />*/}
        {/*</div>*/}
        <div className="belly-yellow2">
          {/*<img src="/image/bellyYellow2.png" alt="Belly Yellow 2" />*/}

          <div className="product-info">
            {productData ? (
                <>
                  <div dangerouslySetInnerHTML={{__html: isPC() ? productData.content : productData.listContentMobile}}/>

                <div className="product-images">
                  <img src={productData.imageFilePath} alt={productData.productName} />
                  {productData.subImg1Path && <img src={productData.subImg1Path} alt="Sub Image 1" />}
                  {productData.subImg2Path && <img src={productData.subImg2Path} alt="Sub Image 2" />}
                  {productData.subImg3Path && <img src={productData.subImg3Path} alt="Sub Image 3" />}
                  {productData.subImg4Path && <img src={productData.subImg4Path} alt="Sub Image 4" />}
                  {productData.subImg5Path && <img src={productData.subImg5Path} alt="Sub Image 5" />}
                </div>

                <div dangerouslySetInnerHTML={{ __html: productData.botContent }} />
              </>
            ) : (
              <p>Loading product details...</p>
            )}
          </div>
        </div>
      </div>

      {/* Footer */}
      <Section05/>
    </div>
  );
};

export default ProductDetail;
