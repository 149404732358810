// import React, { useState, useEffect, useContext } from 'react';
// import { Swiper, SwiperSlide } from 'swiper/react';
// import 'swiper/css';
// import 'swiper/css/pagination';
// import 'swiper/css/navigation';
// import { Pagination, Navigation } from 'swiper/modules';
// import { ApiContext } from '../App';
// import { useNavigate } from 'react-router-dom';
//
// interface SlideData {
//   title: string;
//   subtitle?: string;
//   description: string;
//   shareXLink?: string;
//   shareFacebookLink?: string;
//   eventLink?: string;
//   productLink?: string;
//   buyLink?: string;
// }
//
// const Section02: React.FC = () => {
//   const apiUrl = useContext(ApiContext);
//   const navigate = useNavigate();
//   const [slides, setSlides] = useState<SlideData[]>([]);
//
//   useEffect(() => {
//     const fetchSlides = async () => {
//       try {
//         const response = await fetch(`${apiUrl}/api/notice/list`);
//         const result = await response.json();
//
//         console.log(result);
//         if (result.code === 200) {
//           const fetchedSlides = result.data.map((item: any) => ({
//             title: item.title,
//             description: item.listContent,
//             shareXLink: `https://www.facebook.com/sharer/sharer.php?u=http://xrp-plus.site/pages/eventDetail/${item.eventSeq}`,
//             shareFacebookLink: `https://www.facebook.com/sharer/sharer.php?u=http://xrp-plus.site/pages/eventDetail/${item.eventSeq}`,
//             eventLink: `/pages/eventDetail/${item.eventSeq}`,
//             productLink: `/pages/ProductDetail/${item.shopSeq}`,
//             buyLink: `/pages/ProductBuy/${item.productSeq}`,
//           }));
//
//           setSlides(fetchedSlides);
//         } else {
//           console.error('Error fetching slides:', result.message);
//         }
//       } catch (error) {
//         console.error('Failed to fetch slides:', error);
//       }
//     };
//
//     fetchSlides();
//   }, [apiUrl]);
//
//   console.log('!!!!!!');
//   return (
//     <section id="section02">
//       <article>
//         <div className="swiper-location">
//           <div className="merch-section">
//             <div className="right_cha">
//               <img src="/image/big_pen.svg" alt="Right character" />
//             </div>
//             <div className="left_cha">
//               <img src="/image/big_pen_balloon.svg" alt="Left character" />
//             </div>
//             <div className="container" style={{ position: 'relative' }}>
//               <div className="small_img2">
//                 <img src="/image/left_ice2.png" className="fl" alt="Left Ice" />
//                 <img src="/image/right_ice.png" className="fr" alt="Right Ice" />
//               </div>
//
//               <Swiper
//                 modules={[Pagination, Navigation]}
//                 pagination={{ clickable: true }}
//                 navigation
//                 className="mySwiper"
//               >
//                 {slides.map((slide, index) => (
//                   <SwiperSlide key={index}>
//                     <div className="d_box2">
//                       <div className="detail_box">
//                         <h3 dangerouslySetInnerHTML={{ __html: slide.description }} />
//                       </div>
//                       <div className="button_box2">
//
//                         {slide.productLink && (
//                           <button
//                             className="box_btn01"
//                             onClick={() => navigate(slide.productLink!)}
//                           >
//                             <img src="/image/botn_01.png" />
//                           </button>
//                         )}
//
//                         {slide.buyLink && (
//                           <button
//                             className="box_btn02"
//                             onClick={() => navigate(slide.buyLink!)}
//                           >
//                             <img src="/image/botn_02.png" />
//                           </button>
//                         )}
//
//                         {/*
//                         {slide.eventLink && (
//                           <button
//                             className="box_btn03"
//                             onClick={() => navigate(slide.eventLink!)}
//                           >
//                             <img src="/image/botn_03.png" />
//                           </button>
//                         )}*/}
//
//                         <span className="event_back">.</span>
//                       </div>
//                     </div>
//                   </SwiperSlide>
//                 ))}
//               </Swiper>
//
//               <div className="swiper-pagination"></div>
//             </div>
//           </div>
//         </div>
//         <div className="right_btn">
//           <span className="btn swiper-button-next">다음</span>
//           <br />
//           <span className="btn swiper-button-prev">이전</span>
//         </div>
//       </article>
//     </section>
//   );
// };
//
// export default Section02;

import React, { useState, useEffect, useContext } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Pagination, Navigation, Autoplay } from 'swiper/modules';
import { ApiContext } from '../App';
import { useNavigate } from 'react-router-dom';

interface SlideData {
  title: string;
  subtitle?: string;
  description: string;
  descriptionMobile: string;
  shareXLink?: string;
  shareFacebookLink?: string;
  eventLink?: string;
  productLink?: string;
  buyLink?: string;
  status?: string;
  salePossible?: string;
  activeCheck?: string;
}

interface Section02Props {
  bg_img2: String;
  setVisibleSlide: (bool: boolean) => void;
}

const Section02: React.FC<Section02Props> = ({bg_img2, setVisibleSlide}) => {
  const apiUrl = useContext(ApiContext);
  const navigate = useNavigate();
  const [slides, setSlides] = useState<SlideData[]>([]);

  useEffect(() => {
    const fetchSlides = async () => {
      try {
        const response = await fetch(`${apiUrl}/api/notice/list`);
        const result = await response.json();

        // console.log('result :', result);
        if (result.code === 200) {
          const fetchedSlides = result.data.map((item: any) => ({
            title: item.title,
            description: item.listContent,
            descriptionMobile: item.listContentMobile,
            shareXLink: `https://www.facebook.com/sharer/sharer.php?u=http://xrp-plus.site/pages/eventDetail/${item.eventSeq}`,
            shareFacebookLink: `https://www.facebook.com/sharer/sharer.php?u=http://xrp-plus.site/pages/eventDetail/${item.eventSeq}`,
            eventLink: `/pages/eventDetail/${item.eventSeq}`,
            productLink: `/pages/ProductDetail/${item.shopSeq}`,
            buyLink: `/pages/ProductBuy/${item.productSeq}`,
            status:`${item.status}`,
            salePossible:`${item.salePossible}`
          }));

          console.log(fetchedSlides);
          setSlides(fetchedSlides);

          if(fetchedSlides.length > 0 ){
            setVisibleSlide(true);
          }else{
            setVisibleSlide(false);
          }
        } else {
          console.error('Error fetching slides:', result.message);
          setVisibleSlide(false);
        }
      } catch (error) {
        console.error('Failed to fetch slides:', error);
        setVisibleSlide(false);
      }
    };

    fetchSlides();
  }, [apiUrl]);

  function isPC() {
    const userAgent = navigator.userAgent;

    // 모바일 기기에 해당하는 키워드
    const mobileKeywords = [
      "Android", "iPhone", "iPad", "iPod", "BlackBerry", "Windows Phone", "webOS"
    ];

    // User Agent에 모바일 키워드가 포함되어 있으면 모바일로 간주
    return !mobileKeywords.some(keyword => userAgent.includes(keyword));
  }

  const handleTouchMove = (swiper: { allowSlideNext: boolean; allowSlidePrev: boolean; }) => {
    swiper.allowSlideNext = false;
    swiper.allowSlidePrev = false;
  };


  return (

      <>
      <section id="shop" style={{backgroundImage: `url(${bg_img2})`}}>
        <article>

          {/*<div className="notice_area">*/}
          {/*  <div className="detail_box">*/}
          {/*    <h3><figure className="image"><img src="https://baekho-image.s3.amazonaws.com/0fd59234-0abf-4ac0-95e3-599bd2567876.png"/></figure></h3>*/}
          {/*  </div>*/}
          {/*</div>*/}
          {/*<div className="button_box2">*/}
          {/*  <button className="box_btn01">*/}
          {/*    <img src="/image/botn_01.png"/>*/}
          {/*  </button>*/}
          {/*  <button className="box_btn02">*/}
          {/*    <img src="/image/botn_02.png"/>*/}
          {/*  </button>*/}
          {/*  <span className="event_back">.</span>*/}
          {/*</div>*/}

            <Swiper
                modules={[Pagination, Navigation, Autoplay]}
                pagination={{ clickable: true }}
                loop={true} // 무한 루프 활성화
                slidesPerView={1} // 한 번에 보이는 슬라이드 개수
                centeredSlides={true} // 아이템들이 중앙에 오도록 설정
                onTouchMove={handleTouchMove} // 터치 시 스와이프 방지
                autoplay={{
                  delay: 5000,
                  disableOnInteraction: false,
                }}
            >
              {slides.map((slide, index) => (
                  <SwiperSlide key={index}>
                    <div className={'notice_area'}>
                          <div className={'detail_box'} style={{paddingLeft: '4px'}} dangerouslySetInnerHTML={{ __html: `<h3>${isPC() ? slide.description : slide.descriptionMobile}</h3>` }} />
                    </div>
                      <div className="button_box2">

                        {slide.productLink && (
                            <button
                                className="box_btn01"
                                onClick={() => navigate(slide.productLink!)}
                            >
                              <img src="/image/botn_01.png"/>
                            </button>
                        )}

                        {
                          slide.salePossible === "true" ? (
                              slide.status === "Y" ? (
                                  slide.buyLink ? (
                                      <button
                                          className="box_btn02"
                                          onClick={() => navigate(slide.buyLink!)}
                                      >
                                        <img src="/image/botn_02.png" alt="Button Icon"/>
                                      </button>
                                  ) : null
                              ) : null
                          ) : null
                        }


                        <span className="event_back">.</span>
                      </div>

                  </SwiperSlide>
              ))}
            </Swiper>
        </article>
      </section>
        </>
  );
};

export default Section02;
