import React, { useState } from 'react';



interface SocialListProps {
    isModalOpen: boolean;
    closeModal: () => void;
}

const SocialList: React.FC<SocialListProps> = ({isModalOpen,closeModal}) => {

  return (
      <div>
        {isModalOpen && (
          <>
            <div className="modal-overlay" onClick={closeModal}></div>

            <div className="X-Social-eventModal eventModal" tabIndex={-1} role="dialog" aria-modal="true">
              <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content min-h-auto bg_white" style={{ border: '3px solid #00142D' }}>
                  <button onClick={closeModal} className="modal-close-btn2 w-ba">
                    <img src="/image/close.png" alt="Close" />
                  </button>
                  <div className="wallbody xModal">
                    <div className="modal-body">
                      <ul>
                          <li><img className={"X-img"} src="/image/X-Log.png"/><a href="https://x.com/bellygom_nft">Bellyland</a></li>
                          <li><img className={"X-img"} src="/image/X-Log.png"/><a href="https://x.com/pudgypenguins">Pudgy Penguins</a></li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
  );
};

export default SocialList;
