import React, {useState, useEffect} from 'react';
// import Header from '../components/HeaderSub';
import Header from '../components/Header';
import Section05 from '../components/Section05';
import '../css/pages/Merge.css';


interface imgList {
    tokenId: number;
    name: string;
    description: string;
    image: string;
    isPurgeMerged: boolean;
    attributes: [];
    animation_url: string;
}


const Merge: React.FC = () => {
    const [checkedItems, setCheckedItems] = useState<number[]>([]);
    const [search, setSearch] = useState('');
    const [checkID, setCheckID] = useState(0);
    const [imgList, setImgList] = useState<imgList[]>([{
        tokenId: 2,
        name: 'BellyLand #2',
        description: "# BellyLand\n\n“BellyLand” represents the second collection featuring Bellygom, an immensely popular IP character in Korea that was created by Lotte Group, one of South Korea’s leading conglomerates.\nBellyLand showcases Bellygom’s next journey into an exciting adventure. Through the world of Bellygom and BellyLand, you will unlock the vision of Lotte Group’s web3 and gain access to the future with Lotte Group.",
        image: "https://cdn.bellygom.scv.sh/bellyland/assets/Nobelly_Belly-sailles-Day.png",
        isPurgeMerged: false,
        attributes: [],
        animation_url: "https://cdn.bellygom.scv.sh/bellyland/belly-saille-no-belly.html"
    },
        {
            tokenId: 3,
            name: "BellyLand #3",
            description: "# BellyLand\n\n“BellyLand” represents the second collection featuring Bellygom, an immensely popular IP character in Korea that was created by Lotte Group, one of South Korea’s leading conglomerates.\nBellyLand showcases Bellygom’s next journey into an exciting adventure. Through the world of Bellygom and BellyLand, you will unlock the vision of Lotte Group’s web3 and gain access to the future with Lotte Group.",
            image: "https://cdn.bellygom.scv.sh/bellyland/assets/Nobelly_ForgottenTemple-Day.png",
            isPurgeMerged: false,
            attributes: [],
            animation_url: "https://cdn.bellygom.scv.sh/bellyland/forgotten-temple-no-belly.html"
        },
        {
            tokenId: 4,
            name: "BellyLand #4",
            description: "# BellyLand\n\n“BellyLand” represents the second collection featuring Bellygom, an immensely popular IP character in Korea that was created by Lotte Group, one of South Korea’s leading conglomerates.\nBellyLand showcases Bellygom’s next journey into an exciting adventure. Through the world of Bellygom and BellyLand, you will unlock the vision of Lotte Group’s web3 and gain access to the future with Lotte Group.",
            image: "https://cdn.bellygom.scv.sh/bellyland/assets/Nobelly_ForgottenTemple-Day.png",
            isPurgeMerged: false,
            attributes: [],
            animation_url: "https://cdn.bellygom.scv.sh/bellyland/forgotten-temple-no-belly.html"
        }]);
    const [searchResults, setSearchResults] = useState<imgList[]>(imgList); // 검색 결과 상태

    const MetaMaskKey = localStorage.getItem('MetaMask');

    // const handleCheckboxChange = (index: number) => {
    //   setCheckedItems(prev =>
    //     prev.includes(index) ? prev.filter(item => item !== index) : [...prev, index]
    //   );
    // };

    const handleCheckboxChange = (token: number) => {
        if (checkID === token) {
            setCheckID(0);
        } else {
            setCheckID(token);
        }

    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            try {
                if (!MetaMaskKey) {
                    return false;
                }
                // var headers = new Headers();
                // headers.append("X-CSCAPI-KEY", "d1U2cmYwa25TelI5UzdDeVlEU09LTWJ5eXFuQjNQTnpIY1EwNDFRQw==");

                var requestOptions = {
                    method: 'GET',
                    // headers: {
                    //     "Cache-Control": "no-store",  // 캐시를 사용하지 않도록 설정
                    // },
                    // mode: 'no-cors'
                };

                //0x64220B85356c2E31AA5B2DD79322dbAB63d05f22
                // 1. 0xAFd8e008275aE08a949656B4faA793158Df81ada
                // 2. 0x8dd6CCb3300fa4C3497aA6D18F2CcaA2D311d360
                // 3. 0xD50C8e9Ff4D9482ee0edDAde5bF02ac268b4Af80
                // 4. 0x454383190c3fC7c1887f34F8D25A6d8a20D8FcE7
                // 5. 0xC47Dbc6ed5C03d7d7E9e62223aa086Ad31D4CE3b
                // @ts-ignore
                fetch(`https://merge-api.bellygom.scv.sh/api/usernfts?address=${MetaMaskKey}`, requestOptions)
                    .then((response) => response.text())  // 응답을 텍스트로 받아옴
                    .then((result) => {
                        console.log('result :', result);
                        if (!result) {
                            throw new Error("Empty response from server");
                        }
                        const jsonResult = JSON.parse(result);  // JSON으로 변환
                        console.log("Parsed JSON: ", jsonResult);
                    })
                    .catch((error) => {
                        console.error("Error: ", error);  // 에러 발생 시 처리
                    });
            } catch (error) {
                console.error('Failed to fetch event data:', error);
            }
        };

        fetchData();
    }, [MetaMaskKey]);


    // 이름에 검색어가 포함되어 있는지 확인하는 함수
    const searchByName = (searchTerm: string) => {
        if (searchTerm.trim() === '') {
            // 검색어가 없으면 전체 리스트를 반환
            setSearchResults(imgList);
        } else {
            // 검색어가 있으면 해당 단어가 포함된 항목만 필터링
            const filteredList = imgList.filter(item =>
                item.name.toLowerCase().includes(searchTerm.toLowerCase())
            );
            setSearchResults(filteredList);
        }
    };

    const updateMerge = () => {
        if (checkID === 0) {

            return false;
        } else {
            alert(checkID);
        }
    };


    return (
        <div className="project_bg">
            <Header/>
            <section id="project" className={"merge-bg"}>
                <div className="merge-section">
                    <div className="container">
                        <div className="search_box">
                            <input placeholder="Search" onChange={(e) => {
                                setSearch(e.target.value)
                            }}/>
                            <button onClick={() => searchByName(search)}><img src="/image/zoom.png" alt="Search"/>
                            </button>
                        </div>
                        <h2>If you check the item, the MERGE button will be activated!</h2>
                        <div className="merge-panel dflex-ac-jc">

                            {searchResults.map((_el, index) => (
                                <a href="javascript: void(0)" onClick={() => handleCheckboxChange(_el.tokenId)}
                                   key={index} className="merge-btn">
                                    <div className="g-panel w-ba">

                                        {/*<input*/}
                                        {/*    type="checkbox"*/}
                                        {/*    checked={checkedItems.includes(index)}*/}
                                        {/*    onChange={() => handleCheckboxChange(index)}*/}
                                        {/*/>*/}
                                        <input
                                            type="checkbox"
                                            checked={checkID === _el.tokenId ? true : false}
                                            onChange={() => handleCheckboxChange(_el.tokenId)}
                                        />
                                        <div className={"merge-image"}>
                                            <img className="g-img" src={`${_el.image}`}
                                                 alt={`Pudgy Penguin ${index + 1}`}/>
                                        </div>
                                        <div className={"merge-text"}>
                                            <p>{_el.name}</p>
                                            <span>#{_el.tokenId}</span>
                                        </div>

                                    </div>
                                </a>
                            ))}
                            {/*{Array.from({ length: 10 }).map((_, index) => (*/}
                            {/*  <a href="#" key={index} className="merge-btn">*/}
                            {/*    <div className="g-panel w-ba">*/}

                            {/*        <input*/}
                            {/*            type="checkbox"*/}
                            {/*            checked={checkedItems.includes(index)}*/}
                            {/*            onChange={() => handleCheckboxChange(index)}*/}
                            {/*        />*/}
                            {/*      <div className={"merge-image"}>*/}
                            {/*        <img className="g-img" src={`/image/merge_0${index + 1}.png`} alt={`Pudgy Penguin ${index + 1}`}/>*/}
                            {/*      </div>*/}
                            {/*      <div className={"merge-text"}>*/}
                            {/*        <p>Pudgy Penguins</p>*/}
                            {/*            <span>#3118</span>*/}
                            {/*        </div>*/}

                            {/*    </div>*/}
                            {/*  </a>*/}
                            {/*))}*/}
                        </div>
                    </div>
                    <div className="merge_code">
                        <div className={"merge-number"}>
                            <span className={"merge-numb"}>NUMBER</span>
                            <input type="text"/>
                        </div>
                        <button
                            // className={`merge_btn ${checkedItems.length > 0 ? 'active' : 'disabled'}`}
                            // disabled={checkedItems.length === 0}
                            className={`merge_btn ${checkID === 0 ? 'active' : 'disabled'}`}
                            disabled={checkID === 0}
                            onClick={() => updateMerge()}
                        >
                            MERGE
                        </button>

                    </div>
                </div>
            </section>
            <Section05/>
        </div>
    );
};

export default Merge;

// 빈 export 구문 추가
export {};
