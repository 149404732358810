import React, {useContext, useEffect, useState} from 'react';
import '../terms.css';
import {useNavigate} from "react-router-dom";

interface TermsProps {
    closeModal: () => void;
}
const PrivacyPolicy: React.FC<TermsProps> = ({ closeModal }) => {
    const navigate = useNavigate();
    return (
        <div className="termsofUseModal closeM modal fade"  role="dialog" aria-modal="true"
             style={{paddingRight: '17px' }}>
            <div className="modal-dialog-centered" role="document">
                <div className="modal-content min-h-auto">
                    <button className="m-close-btn" data-modal="termsofUseModal" id="closeModalButton"  onClick={()=> {closeModal();}}>
                        <img src="/image/close_modal.png"/>
                    </button>
                    <div className="modal-panel w-100">
                        <div className="modal-body mt-md-4 mb-4 mt-2">
                            <div className="text-left mb-4">
                                <h4 className="mb-0 font-bold ">Privacy Policy</h4>
                            </div>
                            <div className="form-container scrollable maxHeight" style={{maxHeight: '460px'}}>
                                <p>
                                    <p>L.AND SHOP (the “Service”), operated by Daehong Communications (the “Company”),
                                        is committed to being transparent about our data and privacy processes and to
                                        protecting your privacy online.</p>
                                    <p>This Privacy Policy describes the information we collect through our apps and
                                        websites, how it may be used or disclosed, and what steps we take to protect the
                                        information we collect (“Privacy Policy”).
                                    </p>
                                </p>

                                <p>1. Purpose of collecting and using personal information
                                </p>
                                <p>The Company processes personal information for the following purposes. The personal
                                    information processed will not be used for any purpose other than the following
                                    purposes, and if the purpose of use changes, we will take necessary measures such as
                                    obtaining separate consent.
                                </p>

                                <p>- To provide goods or services to users (buyers) (delivery of goods services)
                                </p>
                                <p>- Providing priority purchase services (verifying your identity and communicating
                                    with you when you obtain priority purchase rights)
                                </p>

                                <p>- If we believe it is necessary or appropriate to comply with the legal process,
                                    enforce our Terms of Use, operate the service smoothly, or protect our rights and
                                    the rights of others.
                                </p>
                                <p>2. The types and the purpose of personal information we collect
                                </p>


                                <p>In the course of providing services to you, we and our service providers collect
                                    information about you. We collect information that you provide directly to us.
                                </p>
                                <p>We will not sell or rent your information except as described in this policy (2.3
                                    Information Rights) in the context of a successor to the Company's rights and
                                    obligations, such as in the event of a sale, merger, or otherwise.
                                </p>
                                <p>The information provided and collected from you is stored and managed at our
                                    operational locations.
                                </p>
                                <p>Country and Location: South Korea (Seoul, Oant)
                                </p>
                                <p>Information collector: Daehong Communications Inc.
                                </p>
                                <p>Collected information: Name, email, phone number, address and zip code,
                                    cryptocurrency wallet address
                                </p>
                                <p>When and how it is transferred: Transmission via network at the time of service use
                                </p>
                                <p>E-mail: master@oant.co.kr
                                </p>


                                <p>2.1 Information You Provide to Purchase Products
                                </p>
                                <p>- When you purchase goods from the L.AND SHOP, you provide us with your name, email,
                                    phone number, address, zip code, and cryptocurrency wallet address.
                                </p>
                                <p>- We may also use your email address to communicate with you about your purchase and
                                    delivery of our goods and to respond to you when you contact us.
                                </p>
                                <p>- We only collect information that is necessary for the purchase and delivery of
                                    goods. We do not sell or rent information, nor do we create profiles for
                                    advertising.
                                </p>
                                <p>2.2 Information Collected Automatically
                                </p>
                                <p>The Company does not use 'cookies' that store and retrieve the usage information of
                                    the information subject from time to time, and no information is collected
                                    automatically.
                                </p>
                                <p>2.3 Information Rights
                                </p>


                                <p>- Except as set forth in this Privacy Policy or as otherwise permitted under
                                    applicable law, we do not share, disclose, or sell the information we collect with
                                    third parties, except as follows
                                </p>
                                <p>We use service providers to operate the Merchandise Purchase Service. These service
                                    providers only use the information for the services we contract with them for, and
                                    they are held to the same level of data protection as this Privacy Policy.</p>
                                <p>* Country and location: South Korea (Seoul)
                                </p>
                                <p>* Company name: Oant
                                </p>
                                <p>* Time and method of transfer: Transfer via network at the time of service use
                                </p>
                                <p>* Personal information protection agent: Hyo-seok Choi
                                </p>
                                <p>* Address: 301ho, Geumgang Penterium IT Tower, 171 Dangsan-ro, Yeongdeungpo-gu,
                                    Seoul, Korea</p>

                                <p>* Contact: master@oant.co.kr
                                </p>
                                <p>In the event of a merger of our company, sale of our assets, or negotiation of all or
                                    part of our company to another company, or in connection with an emergency to
                                    protect the personal safety of any person, we will require the new owner to
                                    continue </p>
                                <p>to honor the terms of this Privacy Policy, or we will provide you with notice and an
                                    opportunity to opt out of the transfer by deleting your data.
                                </p>


                                <p>2.4 Data Subject Rights and How to Exercise Them, Including Access to Personal
                                    Information, Correction, and Withdrawal of Consent

                                </p>
                                <p>- We will process your requests to access, correct, or withdraw consent to your
                                    personal information without delay. Therefore, you may access or correct your
                                    personal information or that of a child under the age of 16 registered with us at
                                    any time, and you may request to withdraw your consent.
                                </p>
                                <p>- If you wish to withdraw your consent to view or modify your personal information,
                                    please contact us in writing or by phone at representative contact (010-7750-6370)
                                    or the personal information manager, and we will take action without delay.
                                </p>
                                <p>- If you request that we correct an error in your personal information, we will not
                                    use or disclose that personal information until we have completed the correction. In
                                    addition, if you have already provided incorrect personal information to a third
                                    party as described in this Privacy Policy, we will notify the third party of the
                                    results of the correction so that it can be corrected.
                                </p>
                                <p>Personal information that has been terminated or deleted at your request will be
                                    processed as set out in the 'How long we retain and use your personal information'
                                    notice provided at the time of collection and will not be accessible or usable for
                                    any other purpose. However, we may restrict or refuse a request in the following
                                    cases, and we will notify you without delay. When there are special provisions in
                                    the law or when it is unavoidable to comply with obligations under the law When
                                    there is a possibility of harming the life, body, or property of another person or
                                    unreasonably infringing on the property and other interests of another person When
                                    it is difficult to fulfill the contract, such as not being able to provide the
                                    services agreed with the member, if the personal information is not processed, and
                                    the member has not clearly expressed his/her intention to terminate the contract
                                </p>
                                <p>3. Storage and destruction of personal information
                                </p>
                                <p>- In principle, we will destroy the personal information provided by the user without
                                    delay after the user's request for deletion, the fulfillment of the purpose of using
                                    the personal information, and the expiration of the period of retention and use of
                                    the personal information. However, if we obtain separate consent for the retention
                                    period of a user's personal information, or if laws and regulations impose an
                                    obligation to retain it for a certain period of time, we will move the personal
                                    information to a </p>
                                <p>separate database (DB) or store it in a different location and keep it securely
                                    during that period.</p>


                                <p>- In accordance with the Personal Information Protection Act, your personal
                                    information will be kept for the retention period (1 month from the date of
                                    delivery) agreed upon at the time of collection for the purpose of using
                                    (purchasing) the service for similar goods and services, and then destroyed.
                                </p>
                                <p>- Other retention and use periods under applicable laws are as follows.</p>
                                <p>* Laws on consumer protection in e-commerce, etc.
                                </p>
                                <p>Supply records, such as withdrawal from a contract or offer: 5 years
                                </p>
                                <p>Records of payment, supply of goods, etc.: 5 years
                                </p>
                                <p>Records on handling consumer complaints or disputes: 3 years
                                </p>
                                <p>- Destruction procedures and methods What to destroy
                                </p>
                                <p>Personal information is destroyed with the approval of our Privacy Officer. If it is
                                    in electronic form, we will securely delete it so that it cannot be recovered or
                                    reproduced, and if it is in paper form, we will destroy it by shredding or
                                    incineration.
                                </p>
                                <p>4. Ads and Payments
                                </p>
                                <p>- There is no third party advertising in the L.AND SHOP's services and L.AND SHOP
                                    does not utilize or disclose any personal information you provide.
                                </p>


                                <p>- Information related to your payment for goods is managed by Oant and is destroyed
                                    one month after delivery.
                                </p>
                                <p>5. legal basis for processing personal data
                                </p>


                                <p>- If you are an individual from the European Union (EU) or an EU citizen, we will
                                    only collect and process data about you if we have a legal basis for doing so under
                                    applicable EU law, which means that we will only collect and process data about you
                                    if;
                                </p>
                                <p>Necessary for our legitimate interests (that do not violate your privacy), such as
                                    preventing fraud or improving our services.
                                </p>
                                <p>You have consented to this collection and processing.
                                </p>
                                <p>You need to fulfill a contractual obligation.
                                </p>

                                <p>Where we rely on your consent to process your personal data to comply with a legal
                                    obligation, you can withdraw or refuse consent at any time. To withdraw your </p>
                                <p>consent, please contact us using the information in the Contact us to request your
                                    individual rights section. Examples of data we process for our legitimate interests
                                    include the below;
                                </p>

                                <p>Customer support and fraud prevention (email address)
                                </p>
                                <p>- Where we process your personal data based on our interests, you have the right to
                                    object. If you have any questions or require further information about the legal
                                    basis on which we collect and use your personal data, please contact us at
                                    master@oant.co.kr
                                </p>

                                <p>6. Complaint services for personal information
                                </p>
                                <p>- We have designated a Privacy Officer as follows to protect your personal
                                    information and to handle complaints regarding your personal information.
                                </p>
                                <p>Privacy Officer and DPO: Hyo Seok Choi
                                </p>
                                <p>Privacy Manager: Hyo Seok Choi
                                </p>

                                <p>Contact: 010-7750-6370
                                </p>
                                <p>- You can report any privacy-related complaints arising from your use of the L.AND
                                    SHOP service to the personal information manager or the department in charge, and we
                                    will take prompt and sufficient measures to respond to your report.
                                </p>

                                <p>- To report or discuss other privacy breaches, please contact the following
                                    organizations;
                                </p>
                                <p>Cybercrime Investigation Division of the Supreme Prosecutors' Office http://spo.go.kr
                                    ☎ 1301 without area code
                                </p>
                                <p>National Police Agency Cybersecurity Bureau https://ecrm.cyber.go.kr/minwon/main ☎
                                    182 without area code
                                </p>
                                <p>Personal Information Infringement Report Center http://privacy.kisa.or.kr ☎ 118
                                    without area code
                                </p>
                                <p>Personal Information Dispute Mediation Committee http://kopico.go.kr ☎ 02-1833-6972
                                </p>
                                <p>7. Information security and breach notification
                                </p>
                                <p>- We are committed to protecting the security of the information we collect. We
                                    strive to put in place appropriate physical, technical, and procedural safeguards to
                                    help prevent unauthorized access, maintain data security, and ensure the appropriate
                                    use of the information we collect.
                                </p>
                                <p>Encrypt data in transit.
                                </p>
                                <p>Staying up to date on privacy laws and best practices and being educated on the
                                    importance of privacy considerations from product design to service delivery.
                                </p>
                                <p>Limit access to data to only those employees who need it to operate the service.
                                </p>
                                <p>While we cannot prevent all potential security breaches, we make every effort to
                                    provide reasonable security for the information we process and manage.
                                </p>
                                <p>- If we become aware of unauthorized access to personally identifiable information
                                    from a third party and believe that the information may be used in a harmful way, we
                                    will attempt to contact or notify you via the email address you have provided to us.
                                </p>
                                <p>This policy is effective January 3, 2025, and we will provide advance notice if we
                                    revise it.
                                </p>


                                <div className="form-footer flex-wrap mt-4">
                                    <button type="button"
                                            className="walbtn w-30"
                                            id="closeTermsButton"
                                            style={{borderRadius: '30px'}}
                                            onClick={() => {
                                                closeModal();
                                            }}
                                    >Close
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PrivacyPolicy;

// 빈 export 구문 추가
export {};
