import React, { useEffect } from "react";
import {useLocation, useNavigate} from "react-router-dom";

const TradeRegistration = () => {
    // 거래 요청 함수
    const {state} = useLocation();
    console.log(state);
    const {reqData, resData, postData} = state;
    const goReq = () => {
        // 거래등록 처리 정상
        if (resData.Code === "0000") {
            alert("거래등록 성공");
            // document.forms.form_trade_reg.action = "/mobile_sample/order_mobile";
            // document.forms.form_trade_reg.submit();
        } else {
            // 거래등록 처리 실패
            alert(`에러 코드: ${resData.Code}, 에러 메세지: ${resData.Message}`);
            // window.location.href = "/mobile_sample/trade_reg";
        }
    };

    // 컴포넌트가 렌더링될 때 요청 실행
    useEffect(() => {
        goReq();
    }, []);

    return (
        <form name="form_trade_reg" method="post">
            <input type="hidden" name="site_cd" value={reqData?.site_cd} />
            <input type="hidden" name="ordr_idxx" value={reqData.ordr_idxx} />
            <input type="hidden" name="good_mny" value={reqData.good_mny} />
            <input type="hidden" name="good_name" value={reqData.good_name} />
            <input type="hidden" name="Ret_URL" value={reqData.Ret_URL} />
            <input type="hidden" name="pay_method" value={reqData.pay_method} />
            <input type="hidden" name="ActionResult" value={postData.actionResult} />
            <input type="hidden" name="van_code" value={postData.van_code} />
            <input type="hidden" name="approvalKey" value={resData.approvalKey} />
            <input type="hidden" name="traceNo" value={resData.traceNo} />
            <input type="hidden" name="PayUrl" value={resData.PayUrl} />
            <input type="hidden" name="enc_info" value="" />
            <input type="hidden" name="res_cd" value={resData.Code} />
            <input type="hidden" name="res_msg" value={resData.Message} />
        </form>
    );
};

export default TradeRegistration;

// 빈 export 구문 추가
export {};
