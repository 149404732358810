import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {Web3Provider} from '@ethersproject/providers';
import {PetraWallet} from "petra-plugin-wallet-adapter";
import {AptosWalletAdapterProvider} from "@aptos-labs/wallet-adapter-react";
// import { Web3ReactProvider } from '@web3-react/core';
import {MetaMaskProvider} from "@metamask/sdk-react"

const getLibrary = (provider: any) => new Web3Provider(provider);
const wallets = [new PetraWallet()];

// root element 가져오기
const rootElement = document.getElementById('root') as HTMLElement;

// root 인스턴스를 생성하고 render 함수 호출
const root = ReactDOM.createRoot(rootElement);

const api_key = `884b3b7d76974c7dae12f310cff84c8d`;
root.render(
    <React.StrictMode>
        {/*<Web3ReactProvider getLibrary={getLibrary}>*/}
        {/*        <App />*/}
        {/*</Web3ReactProvider>*/}
        <AptosWalletAdapterProvider plugins={wallets} autoConnect={true}>
            <MetaMaskProvider
                sdkOptions={{
                    dappMetadata: {
                        name: "React Dapp",
                        // url: '/merge',
                    },
                    infuraAPIKey: api_key,
                    // Other options.
                }}
            >
                <App/>
            </MetaMaskProvider>
        </AptosWalletAdapterProvider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
