import React, {useEffect, useState, useRef} from 'react';
import {useParams} from 'react-router-dom';
import Header from '../components/HeaderSub';
import '../App.css';

interface AddressProps {
    setCountry: React.Dispatch<React.SetStateAction<string>>;
    setRegion: React.Dispatch<React.SetStateAction<string>>;
    setCity: React.Dispatch<React.SetStateAction<string>>;
    setDeliveryFee: React.Dispatch<React.SetStateAction<number>>;
    country: string;
    quantity: string;
    isDomestic: boolean;
}

// countries 배열의 각 항목 타입 정의
interface Country {
    code: string;
    name: string;
    iso2: string;
}

interface StateList {
    id: string;
    name: string;
    iso2: string;
}

interface CityList {
    id: string;
    name: string;
    iso2: string;
}

const Address: React.FC<AddressProps> = ({setCountry, setRegion, setCity,setDeliveryFee, country, quantity, isDomestic}) => {
    const [countries, setCountries] = useState<Country[]>([]);
    const [states, setStates] = useState<StateList[]>([]);
    const [cityse, setCityse] = useState<CityList[]>([]);
    const [iso2, setIso2] = useState('');
    const [count, setCount] = useState('');
    const [siso, setSiso] = useState('');
    const [cityId, setCityId] = useState('');

    const list =
        [
            {name: "Australia", parcel_1: 41, parcel_2: 55, parcel_3: 68, parcel_4: 93, parcel_5: 125, parcel_6: 148, parcel_7: 161, parcel_8: 186, parcel_9: 218, parcel_10: 250, parcel_11: 254, parcel_12: 279, parcel_13: 311, parcel_14: 343, parcel_15: 375},
            {name: "Austria", parcel_1: 48, parcel_2: 59, parcel_3: 76, parcel_4: 105, parcel_5: 145, parcel_6: 164, parcel_7: 180, parcel_8: 210, parcel_9: 250, parcel_10: 290, parcel_11: 305, parcel_12: 315, parcel_13: 355, parcel_14: 395, parcel_15: 435},
            {name: "Belgium", parcel_1: 52, parcel_2: 80, parcel_3: 107, parcel_4: 128, parcel_5: 164, parcel_6: 208, parcel_7: 235, parcel_8: 256, parcel_9: 292, parcel_10: 328, parcel_11: 363, parcel_12: 384, parcel_13: 420, parcel_14: 456, parcel_15: 492},
            {name: "Brazil", parcel_1: 56, parcel_2: 85, parcel_3: 113, parcel_4: 138, parcel_5: 181, parcel_6: 223, parcel_7: 251, parcel_8: 276, parcel_9: 319, parcel_10: 362, parcel_11: 389, parcel_12: 414, parcel_13: 457, parcel_14: 500, parcel_15: 543},
            {name: "Canada", parcel_1: 49, parcel_2: 65, parcel_3: 82, parcel_4: 112, parcel_5: 152, parcel_6: 177, parcel_7: 194, parcel_8: 224, parcel_9: 264, parcel_10: 304, parcel_11: 306, parcel_12: 336, parcel_13: 376, parcel_14: 416, parcel_15: 456 },
            {name: "China", parcel_1: 25, parcel_2: 29, parcel_3: 34, parcel_4: 44, parcel_5: 54, parcel_6: 73, parcel_7: 78, parcel_8: 88, parcel_9: 98, parcel_10: 108, parcel_11: 122, parcel_12: 132, parcel_13: 142, parcel_14: 152, parcel_15: 162},
            {name: "Czech Republic", parcel_1: 56, parcel_2: 85, parcel_3: 113, parcel_4: 138, parcel_5: 181, parcel_6: 223, parcel_7: 251, parcel_8: 276, parcel_9: 319, parcel_10: 362, parcel_11: 389, parcel_12: 414, parcel_13: 457, parcel_14: 500, parcel_15: 543},
            {name: "Denmark", parcel_1: 46, parcel_2: 61, parcel_3: 78, parcel_4: 107, parcel_5: 149, parcel_6: 168, parcel_7: 185, parcel_8: 214, parcel_9: 256, parcel_10: 298, parcel_11: 310, parcel_12: 321, parcel_13: 363, parcel_14: 405, parcel_15: 447},
            {name: "Finland", parcel_1: 45, parcel_2: 59, parcel_3: 76, parcel_4: 105, parcel_5: 145, parcel_6: 164, parcel_7: 181, parcel_8: 210, parcel_9: 250, parcel_10: 290, parcel_11: 300, parcel_12: 315, parcel_13: 355, parcel_14: 395, parcel_15: 435},
            {name: "France", parcel_1: 52, parcel_2: 80, parcel_3: 107, parcel_4: 129, parcel_5: 164, parcel_6: 209, parcel_7: 236, parcel_8: 258, parcel_9: 293, parcel_10: 328, parcel_11: 365, parcel_12: 387, parcel_13: 422, parcel_14: 457, parcel_15: 492},
            {name: "Germany", parcel_1: 42, parcel_2: 55, parcel_3: 68, parcel_4: 92, parcel_5: 124, parcel_6: 147, parcel_7: 160, parcel_8: 184, parcel_9: 216, parcel_10: 248, parcel_11: 252, parcel_12: 276, parcel_13: 308, parcel_14: 340, parcel_15: 372},
            {name: "Hong Kong S.A.R.", parcel_1: 21, parcel_2: 26, parcel_3: 31, parcel_4: 42, parcel_5: 56, parcel_6: 68, parcel_7: 73, parcel_8: 84, parcel_9: 98, parcel_10: 112, parcel_11: 115, parcel_12: 126, parcel_13: 140, parcel_14: 154, parcel_15: 168},
            {name: "Hungary", parcel_1: 46, parcel_2: 61, parcel_3: 78, parcel_4: 107, parcel_5: 149, parcel_6: 168, parcel_7: 185, parcel_8: 214, parcel_9: 256, parcel_10: 298, parcel_11: 310, parcel_12: 321, parcel_13: 363, parcel_14: 405, parcel_15: 447},
            {name: "India", parcel_1: 28, parcel_2: 36, parcel_3: 44, parcel_4: 63, parcel_5: 95, parcel_6: 99, parcel_7: 107, parcel_8: 126, parcel_9: 158, parcel_10: 190, parcel_11: 200, parcel_12: 210, parcel_13: 221, parcel_14: 253, parcel_15: 285},
            {name: "Ireland", parcel_1: 45, parcel_2: 59, parcel_3: 76, parcel_4: 105, parcel_5: 145, parcel_6: 164, parcel_7: 181, parcel_8: 210, parcel_9: 250, parcel_10: 290, parcel_11: 300, parcel_12: 315, parcel_13: 355, parcel_14: 395, parcel_15: 435},
            {name: "Israel", parcel_1: 62, parcel_2: 95, parcel_3: 126, parcel_4: 152, parcel_5: 197, parcel_6: 247, parcel_7: 278, parcel_8: 304, parcel_9: 349, parcel_10: 394, parcel_11: 430, parcel_12: 456, parcel_13: 501, parcel_14: 546, parcel_15: 591},
            {name: "Italy", parcel_1: 71, parcel_2: 86, parcel_3: 100, parcel_4: 110, parcel_5: 141, parcel_6: 196, parcel_7: 210, parcel_8: 220, parcel_9: 251, parcel_10: 282, parcel_11: 320, parcel_12: 330, parcel_13: 361, parcel_14: 392, parcel_15: 423 },
            {name: "Taiwan", parcel_1: 23, parcel_2: 29, parcel_3: 36, parcel_4: 49, parcel_5: 66, parcel_6: 78, parcel_7: 85, parcel_8: 98, parcel_9: 115, parcel_10: 132, parcel_11: 134, parcel_12: 147, parcel_13: 164, parcel_14: 181, parcel_15: 198 },
            {name: "Japan", parcel_1: 25, parcel_2: 29, parcel_3: 34, parcel_4: 44, parcel_5: 54, parcel_6: 73, parcel_7: 78, parcel_8: 88, parcel_9: 98, parcel_10: 108, parcel_11: 122, parcel_12: 132, parcel_13: 142, parcel_14: 152, parcel_15: 162},
            {name: "Malaysia", parcel_1: 25, parcel_2: 32, parcel_3: 39, parcel_4: 54, parcel_5: 73, parcel_6: 86, parcel_7: 93, parcel_8: 108, parcel_9: 127, parcel_10: 146, parcel_11: 147, parcel_12: 162, parcel_13: 181, parcel_14: 200, parcel_15: 219},
            {name: "Netherlands", parcel_1: 45, parcel_2: 59, parcel_3: 76, parcel_4: 105, parcel_5: 145, parcel_6: 164, parcel_7: 181, parcel_8: 210, parcel_9: 250, parcel_10: 290, parcel_11: 300, parcel_12: 315, parcel_13: 355, parcel_14: 395, parcel_15: 435},
            {name: "New Zealand", parcel_1: 50, parcel_2: 77, parcel_3: 103, parcel_4: 124, parcel_5: 169, parcel_6: 201, parcel_7: 227, parcel_8: 248, parcel_9: 293, parcel_10: 338, parcel_11: 351, parcel_12: 372, parcel_13: 417, parcel_14: 462, parcel_15: 507},
            {name: "Norway", parcel_1: 56, parcel_2: 85, parcel_3: 113, parcel_4: 138, parcel_5: 181, parcel_6: 223, parcel_7: 251, parcel_8: 276, parcel_9: 319, parcel_10: 362, parcel_11: 389, parcel_12: 414, parcel_13: 457, parcel_14: 500, parcel_15: 543},
            {name: "Philippines", parcel_1: 32, parcel_2: 51, parcel_3: 69, parcel_4: 79, parcel_5: 99, parcel_6: 130, parcel_7: 148, parcel_8: 158, parcel_9: 178, parcel_10: 198, parcel_11: 227, parcel_12: 237, parcel_13: 257, parcel_14: 277, parcel_15: 297},
            {name: "Poland", parcel_1: 56, parcel_2: 85, parcel_3: 113, parcel_4: 138, parcel_5: 181, parcel_6: 223, parcel_7: 251, parcel_8: 276, parcel_9: 319, parcel_10: 362, parcel_11: 389, parcel_12: 414, parcel_13: 457, parcel_14: 500, parcel_15: 543},
            {name: "Portugal", parcel_1: 56, parcel_2: 85, parcel_3: 113, parcel_4: 138, parcel_5: 181, parcel_6: 223, parcel_7: 251, parcel_8: 276, parcel_9: 319, parcel_10: 362, parcel_11: 389, parcel_12: 414, parcel_13: 457, parcel_14: 500, parcel_15: 543},
            {name: "Singapore", parcel_1: 21, parcel_2: 27, parcel_3: 33, parcel_4: 45, parcel_5: 59, parcel_6: 72, parcel_7: 78, parcel_8: 90, parcel_9: 104, parcel_10: 118, parcel_11: 123, parcel_12: 135, parcel_13: 149, parcel_14: 163, parcel_15: 177},
            {name: "South Korea", parcel_1: 3500, parcel_2: 7000, parcel_3: 10500, parcel_4: 14000, parcel_5: 17500, parcel_6: 21000, parcel_7: 24500, parcel_8: 28000, parcel_9: 31500, parcel_10: 35000, parcel_11: 38500, parcel_12: 42000, parcel_13: 45500, parcel_14: 49000, parcel_15: 52500},
            {name: "Spain", parcel_1: 52, parcel_2: 80, parcel_3: 107, parcel_4: 129, parcel_5: 164, parcel_6: 209, parcel_7: 236, parcel_8: 258, parcel_9: 293, parcel_10: 328, parcel_11: 365, parcel_12: 387, parcel_13: 422, parcel_14: 457, parcel_15: 492 },
            {name: "Sweden", parcel_1: 45, parcel_2: 59, parcel_3: 75, parcel_4: 103, parcel_5: 143, parcel_6: 162, parcel_7: 178, parcel_8: 206, parcel_9: 246, parcel_10: 286, parcel_11: 300, parcel_12: 309, parcel_13: 349, parcel_14: 389, parcel_15: 429},
            {name: "Switzerland", parcel_1: 48, parcel_2: 64, parcel_3: 82, parcel_4: 113, parcel_5: 156, parcel_6: 177, parcel_7: 195, parcel_8: 226, parcel_9: 269, parcel_10: 312, parcel_11: 320, parcel_12: 339, parcel_13: 382, parcel_14: 425, parcel_15: 468},
            {name: "Thailand", parcel_1: 21, parcel_2: 26, parcel_3: 31, parcel_4: 42, parcel_5: 56, parcel_6: 68, parcel_7: 73, parcel_8: 84, parcel_9: 98, parcel_10: 112, parcel_11: 115, parcel_12: 126, parcel_13: 140, parcel_14: 154, parcel_15: 168},
            {name: "United Arab Emirates", parcel_1: 57, parcel_2: 79, parcel_3: 107, parcel_4: 124, parcel_5: 157, parcel_6: 203, parcel_7: 231, parcel_8: 248, parcel_9: 281, parcel_10: 314, parcel_11: 355, parcel_12: 372, parcel_13: 405, parcel_14: 438, parcel_15: 471},
            {name: "United Kingdom", parcel_1: 52, parcel_2: 80, parcel_3: 107, parcel_4: 129, parcel_5: 164, parcel_6: 209, parcel_7: 236, parcel_8: 258, parcel_9: 293, parcel_10: 328, parcel_11: 365, parcel_12: 387, parcel_13: 422, parcel_14: 457, parcel_15: 492},
            {name: "United States", parcel_1: 40, parcel_2: 67, parcel_3: 89, parcel_4: 104, parcel_5: 135, parcel_6: 171, parcel_7: 193, parcel_8: 208, parcel_9: 239, parcel_10: 270, parcel_11: 297, parcel_12: 312, parcel_13: 343, parcel_14: 374, parcel_15: 405}
        ];


    function getParcelValueByCountry(list: any[], country: any, quantity: any) {
        // parcel 키 생성
        const parcelKey = `parcel_${quantity}`;

        // 해당 나라의 데이터를 찾음
        const countryData = list.find(item => item.name === country);

        // 데이터가 존재하고 해당 parcel 값이 있으면 반환
        if (countryData && countryData[parcelKey] !== undefined) {
            return countryData[parcelKey];
        }

        // 데이터가 없거나 parcel 값이 없으면 null 반환
        return null;
    }


    useEffect(() => {
        console.log(isDomestic);
        const fetchData = async () => {
            try {
                var headers = new Headers();
                headers.append("X-CSCAPI-KEY", "d1U2cmYwa25TelI5UzdDeVlEU09LTWJ5eXFuQjNQTnpIY1EwNDFRQw==");

                var requestOptions = {
                    method: 'GET',
                    headers: headers,
                    redirect: 'follow'
                };

                // @ts-ignore
                fetch("https://api.countrystatecity.in/v1/countries", requestOptions)
                    .then(response => response.text())
                    .then(result => {
                        const res = JSON.parse(result);
                        const array1: Country[] = res;
                        const array2 = list;
                        let commonItems = [];
                        // const commonItems = isDomestic ? array1.filter(item1 =>
                        //     array2.some(item2 => item2.name === item1.name)
                        // ) : array1.filter(item1 =>
                        //     item1.name === 'South Korea'
                        // ) ;

                        const userLanguage = navigator.languages ? navigator.languages[0] : navigator.language;
                        if (userLanguage.startsWith("ko")) {
                            commonItems = array1.filter(item1 =>
                                item1.name === 'South Korea'
                            ) ;
                        } else {
                            // commonItems = array1.filter(item1 =>
                            //     array2.some(item2 => item2.name === item1.name)
                            // );
                            commonItems = array1.filter(item1 =>
                                item1.name !== 'South Korea' &&
                                array2.some(item2 => item2.name === item1.name)
                            );
                        }
                        setCountries(commonItems);
                    })
                    .catch(error => console.log('error', error));
            } catch (error) {
                console.error('Failed to fetch event data:', error);
            }
        };

        fetchData();
    }, []);

    useEffect(() => {
        if(country){
            const price = getParcelValueByCountry(list, country, quantity);
            setDeliveryFee(price);
            console.log(price);
        }

    }, [country]);

    useEffect(() => {
        const getState = async () => {
            try {
                var headers = new Headers();
                headers.append("X-CSCAPI-KEY", "d1U2cmYwa25TelI5UzdDeVlEU09LTWJ5eXFuQjNQTnpIY1EwNDFRQw==");

                var requestOptions = {
                    method: 'GET',
                    headers: headers,
                    redirect: 'follow'
                };

                // @ts-ignore
                fetch(`https://api.countrystatecity.in/v1/countries/${iso2}/states`, requestOptions)
                    .then(response => response.text())
                    .then(result => {
                        const res = JSON.parse(result);
                        setStates(res);
                    })
                    .catch(error => console.log('error', error));
            } catch (error) {
                console.error('Failed to fetch event data:', error);
            }
        };

        if (iso2) getState();
    }, [iso2]);

    useEffect(() => {
        const getCity = async () => {
            try {
                var headers = new Headers();
                headers.append("X-CSCAPI-KEY", "d1U2cmYwa25TelI5UzdDeVlEU09LTWJ5eXFuQjNQTnpIY1EwNDFRQw==");

                var requestOptions = {
                    method: 'GET',
                    headers: headers,
                    redirect: 'follow'
                };

                // @ts-ignore
                fetch(`https://api.countrystatecity.in/v1/countries/${iso2}/states/${siso}/cities`, requestOptions)
                    .then(response => response.text())
                    .then(result => {
                        const res = JSON.parse(result);
                        setCityse(res);
                    })
                    .catch(error => console.log('error', error));
            } catch (error) {
                console.error('Failed to fetch event data:', error);
            }
        };

        if (siso) getCity();
    }, [siso]);

    const onChange1 = (e: { target: { value: any; }; }) => {
        const {value} = e.target;
        setIso2(value);
        // iso2와 일치하는 국가를 찾기
        const matchingValues = countries.filter(country => country.iso2 === value);

        if (matchingValues.length > 0) {
            setCountry(matchingValues[0].name);  // 첫 번째 일치하는 국가의 name을 사용
        } else {
            setCountry('');  // 일치하는 국가가 없으면 빈 문자열로 설정
        }
    };

    const onChange2 = (e: { target: { value: any; }; }) => {
        const {value} = e.target;
        setSiso(value);

        const matchingValues = states.filter(StateList => StateList.iso2 === value);
        if (matchingValues.length > 0) {
            setRegion(matchingValues[0].name);  // 첫 번째 일치하는 국가의 name을 사용
        } else {
            setRegion('');  // 일치하는 국가가 없으면 빈 문자열로 설정
        }
    };

    const onChange3 = (e: { target: { value: any; }; }) => {
        const {value} = e.target;
        setCityId(value);
        const matchingValues = cityse.filter(CityList => CityList.id == value);
        if (matchingValues.length > 0) {
            setCity(matchingValues[0].name);  // 첫 번째 일치하는 국가의 name을 사용
        } else {
            setCity('');  // 일치하는 국가가 없으면 빈 문자열로 설정
        }
    };

    function CountrySelect() {
        return (
            <select className="select-box" value={iso2} onChange={onChange1}>
                <option value={''}>Select a country</option>
                {countries.map((item, index) => (
                    <option key={index} value={item.iso2} id={item.name}>
                        {item.name}
                    </option>
                ))}
            </select>
        );
    }

    function StateSelect() {
        return (
            <select className="select-box" value={siso} onChange={onChange2} disabled={states.length > 0 ? false : true} >
                <option value={''}>Select a state</option>
                {states.map((item, index) => (
                    <option key={index} value={item.iso2} id={item.name}>
                        {item.name}
                    </option>
                ))}
            </select>
        );
    }

    function CitySelect() {
        return (
            <select className="select-box" value={cityId} onChange={onChange3} disabled={cityse.length > 0 ? false : true}>
                <option value={''}>Select a city</option>
                {cityse.map((item, index) => (
                    <option key={index} value={item.id}>
                        {item.name}
                    </option>
                ))}
            </select>
        );
    }


    return (
        <div className="select-div">
            <CountrySelect/>
            <StateSelect/>
            <CitySelect/>
            {/*<input id={"postCode"} value={postCode} placeholder={'Post Code'} onChange={(e)=> {setPostCode(e.target.value)}} />*/}

        </div>
    );
};

export default Address;
