import React, {useContext, useEffect, useState} from 'react';
import Modal from "react-modal";

import Header from '../components/HeaderSub';
import {ApiContext} from '../App';
import '../payment.css';
import {useLocation, useNavigate} from "react-router-dom";
import HeaderSub from '../components/HeaderSub';
import Address from './Address';
import Terms from './Terms';
import Privacy from './Privacy';
import {useWallet} from "@aptos-labs/wallet-adapter-react";

// window 객체 확장을 위해 선언
declare global {
    interface Window {
        KCP_Pay_Execute_Web?: (form: HTMLFormElement) => void;
    }
}


const Payment: React.FC = () => {
    const {state} = useLocation();
    const apiUrl = useContext(ApiContext);
    const navigate = useNavigate();
    const {account} = useWallet();


    const protocol = document.location.protocol;
    const currentDomain = window.location.hostname;
    const fullURL = protocol + "//" + currentDomain;
    const [checkedItems, setCheckedItems] = useState<number[]>([]);
    const [member_name, setMemberName] = useState('');
    const [member_phone, setMemberPhone] = useState('');
    const [member_email, setMemberEmail] = useState('');
    const [member_address, setMemberAddress] = useState('');
    const [orderid, setOrderid] = useState('');
    const [tprSeq, setTprSeq] = useState('');
    const [isPC, setIsPc] = useState(false);
    //
    const [country, setCountry] = useState('');
    const [region, setRegion] = useState('');
    const [city, setCity] = useState('');
    const [postCode, setPostCode] = useState('');


    const [location, setLocation] = useState(null); // 위치 정보
    const [isDomestic, setIsDomestic] = useState(false); // 국내 여부


    const [isAge, setAge] = useState(false);
    const [isTerms, setTerms] = useState(false);
    const [isPrivacy, setPrivacy] = useState(false);
    const [type, setType] = useState('terms');
    const [delivery_fee, setDeliveryFee] = useState(0);

    function isMobile() {
        const userAgent = navigator.userAgent || navigator.vendor;
        // 모바일 장치 키워드 확인
        return /android|iphone|ipad|ipod|blackberry|windows phone|iemobile/i.test(userAgent);
    }

    useEffect(() => {
        // KCP 스크립트 동적 로드
        const script = document.createElement("script");
        script.src = "https://spay.kcp.co.kr/plugin/kcp_spay_hub.js";
        script.type = "text/javascript";
        document.body.appendChild(script);
        init_orderid();

        return () => {
            // 스크립트 제거
            document.body.removeChild(script);
        };
    }, []);

    useEffect(() => {
        // 위치 정보 가져오기
        const fetchLocation = async () => {
            try {

                const userLanguage = navigator.languages ? navigator.languages[0] : navigator.language;
                if (userLanguage.startsWith("ko")) {
                    console.log('KR!!!!!!!!');
                    setIsDomestic(true);
                } else {
                    console.log('NO!!!!!!!!');
                    setIsDomestic(false);
                }

                // const response0 = await fetch('https://api.ipify.org?format=json');
                // const data0 = await response0.json();
                //
                // console.log(data0);
                // //https://ipapi.co/8.8.8.8/json/
                // // // const response = await fetch("http://ip-api.com/json/");
                // const response = await fetch(`https://ipapi.co/${data0?.ip}/json/`);
                // const data = await response.json();
                // console.log(data);
                // setLocation(data);
                //
                // // 국가 코드가 KR(대한민국)인지 확인
                // if (data.country_code === "KR") {
                //     console.log('KR!!!!!!!!');
                //     setIsDomestic(true);
                // } else {
                //     console.log('NO!!!!!!!!');
                //     setIsDomestic(false);
                // }
            } catch (error) {
                console.error("위치 정보를 가져오는 데 실패했습니다:", error);
            }
        };

        // const getCountry = async () => {
        //     try {
        //         const response = await fetch('https://ipapi.co/json/');
        //         const data = await response.json();
        //         console.log(`당신의 접속 국가는: ${data.country_name} (${data.country})`);
        //     } catch (error) {
        //         console.error('국가 정보를 가져오는 중 오류가 발생했습니다.', error);
        //     }
        // }
        //
        // getCountry();

        fetchLocation();
    }, []);

    const handleCheckboxChange = (index: number) => {
        setCheckedItems(prev =>
            prev.includes(index) ? prev.filter(item => item !== index) : [...prev, index]
        );
    };

    function isInputEmpty(value: any) {
        // return !input?.value?.trim(); // Optional chaining으로 안전하게 접근
        return value == null || (typeof value === 'string' && value.trim().length === 0);
    }

    function validateEmail(email: string) {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    }

    function generateSixDigitNumber() {
        return Math.floor(Math.random() * 900000) + 100000;
    }

    function init_orderid() {
        const today = new Date();
        const year = today.getFullYear();
        let month = today.getMonth() + 1;
        const date = today.getDate();
        const time = today.getTime();

        if (parseInt(String(month)) < 10) {
            month = Number("0" + month);
        }
        const randomSixDigit = generateSixDigitNumber();

        const vOrderID = "PAY" + year + "" + month + "" + date + "" + time + randomSixDigit;
        setOrderid(vOrderID);

    }

    const kcpPayModule = async () => {

        // const input_name = document.getElementById("name");
        // const input_phone = document.getElementById("phone");
        // const input_email = document.getElementById("email") as HTMLInputElement;
        // const input_address = document.getElementById("address");
        console.log('member_name :', member_name);
        console.log('isInputEmpty(member_name) :', isInputEmpty(member_name));
        if (isInputEmpty(member_name)) {
            alert("Please enter your name.");
            return false;
        }
        if (isInputEmpty(member_phone)) {
            alert("Please enter your phone number.");
            return false;
        }
        if (isInputEmpty(member_email)) {
            alert("Please enter your emil.");
            return false;
        } else {
            const email = member_email;
            if (!validateEmail(email)) {
                alert("Invalid email.");
                return false;
            }
        }

        if (country === '') {
            alert("Please enter your country.");
            return false;
        }

        if (region === '') {
            alert("Please enter your state.");
            return false;
        }

        if (isInputEmpty(postCode)) {
            alert("Please enter your Post Code.");
            return false;
        }

        if (isInputEmpty(member_address)) {
            alert("Please enter your address.");
            return false;
        }

        if (isAge === false) {
            alert("Please check the age.");
            return false;
        }

        if (isTerms === false) {
            alert("Please check the terms of use.");
            return false;
        }

        if (isPrivacy === false) {
            alert("Please check the privacy policy.");
            return false;
        }

        // 결제 실행 함수 ${apiUrl}
        try {
            // 데이터 검증
            const response = await fetch(`${apiUrl}/api/product/detail/${state?.product_seq}`);
            const result = await response.json();
            if (result.code === 200) {
            const sold = !isDomestic ? result.data.soldUsdPrice * state.quantity : result.data.soldPrice * state.quantity;
            // const soldUsd = result.data.soldUsdPrice * state.quantity;
            const amount: number = state.amount;
            if (sold === amount) {

            const myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");

            const raw = JSON.stringify({
                "seqNo": localStorage.getItem('seqNo'),
                "productSeq": state?.product_seq,
                "amount": state.amount+delivery_fee,
                "quantity": state?.quantity,
                "recvName": member_name,
                "recvPhone": member_phone,
                "recvEmail": member_email,
                "recvAddress": `${member_address} ${city} ${region} ${country}`,
                "orderNo": orderid,
                "tno": "",
                "walletAddr": account?.address || '',
                "payMethod": "",
                "currency": !isDomestic ? 'USD' : 'WON',
                "product_price" : state.amount,
                "delivery_fee" : delivery_fee,
                "country" : country,
                "price" : state.amount,
                "postCode" : postCode

            });

            const requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: raw,
                redirect: "follow"
            };

            // @ts-ignore
            fetch(`${apiUrl}/api/payment/process`, requestOptions)
                .then((response) => response.text())
                .then((result) => {
                    console.log('result:', result);
                    const res = JSON.parse(result);
                    setTprSeq(res.res.seqNo);
                    if (res.res.status === '0000') {
                        //모바일과 pc 구분
                        if (isMobile()) {
                            handleFormSubmission(res.res.seqNo);
                        } else {
                            setIsPc(true);
                        }
                    }else if(res.res.status==='paymentTimeOut'){
                        alert("payment time out, Please do it again");
                    }
                })
                .catch((error) => console.error(error));

            } else {
                alert('There was a problem with your data. Please try again.');
            }
            } else {
                console.error('Error fetching product:', result.message);
            }

        } catch (e) {
            console.error("결제 오류:", e);
        }
    }

    useEffect(() => {
        const kcpPay = async () => {
            try {
                //@ts-ignore
                const form = document.forms["order_info"];
                if (window?.KCP_Pay_Execute_Web) {
                    window?.KCP_Pay_Execute_Web(form);
                }
            } catch (error) {
                console.error('Failed to fetch product data:', error);
            }
        };

        if (isPC === true) {
            kcpPay();
        }

        return () => {
            setIsPc(false);
        };

    }, [isPC]);

    const createAndSubmitForm = (actionUrl: string, method: "POST" | "GET", formData: Record<string, string>): void => {
        // 1. 폼 요소 생성
        const form = document.createElement("form");
        form.action = actionUrl;
        form.method = method;

        // 2. 폼에 숨겨진 입력 필드 추가
        Object.keys(formData).forEach((key) => {
            const input = document.createElement("input");
            input.type = "hidden";
            input.name = key;
            input.value = formData[key];
            form.appendChild(input);
        });

        // 3. 폼을 DOM에 추가 (필수는 아니지만 브라우저 호환성을 위해)
        document.body.appendChild(form);

        // 4. 폼 제출
        form.submit();

        // 5. 폼 제거
        document.body.removeChild(form);
    };

    const handleFormSubmission = (seqNo: any) => {

        const actionUrl = "https://mobilepay.l-and.shop/mobile_sample/kcp_api_trade_reg";
        const method = "POST";

        const formData = {
            ordr_idxx: `${orderid}`,
            good_name: state.title,
            // good_mny: `${sum(state.amount, delivery_fee, country)}`,
            good_mny:  !isDomestic ? `${state.amount+delivery_fee}00` : state.amount+delivery_fee,
            ActionResult: "card",
            Ret_URL: "https://mobilepay.l-and.shop/mobile_sample/order_mobile",
            user_agent: "",
            site_cd: !isDomestic ? 'AKVEE' : 'AKVDW',
            site_key: !isDomestic ? '4imPgl0-yLEcJ2vwP.S7AtY__' : '0.5xTjKLQFn9cShRte1zguS__',
            currency: !isDomestic ? 'USD' : 'WON',
            // site_cd: 'AKVDW',
            // site_key: '0.5xTjKLQFn9cShRte1zguS__',
            // currency: 'WON',
            site_name: '퍼지펭귄',
            pay_method: "CARD",
            van_code: "",
            eng_flag: !isDomestic ? 'Y' : '',
            param_opt_1: member_name,
            param_opt_2: seqNo,
            //param_opt_3: member_email,
            param_opt_3: fullURL,
            product_price : `${state.amount}`,
            delivery_fee : `${delivery_fee}`,
            country : country
            // used_card_YN : 'Y',
            // used_card : !isDomestic ? 'CCXA:CCXB:CCXC:CCUF' : 'CCDI:CCLG:CCSS:CCNH:CCKM:CCWR:CCLO:CCHN:CCKE:CCXA:CCXB:CCXC:CCUF:CCBC'
            // recvAddress: member_address,
        };



        createAndSubmitForm(actionUrl, method, formData);
    };

    const [isModalOpen, setIsModalOpen] = useState(false);

    const openModal = (el: string) => {
        setType(el);
        setIsModalOpen(true)
    };
    const closeModal = () => {
        setType('');
        setIsModalOpen(false)
    };

    const handleBack = () => {
        navigate(-1); // 히스토리 스택에서 한 단계 뒤로 이동
    };
    const terms = () => {
        alert("Please read and check the terms and conditions");
    };

    //
    const privacy = () => {
        alert("Please read and check the privacy policy");
    };
    // @ts-ignore
    return (
        <>
            <div style={{paddingBottom: '5%'}}>
                <HeaderSub/>
            </div>

            <div>
            <div className="formPopup" style={{ display: "contents", height: '100vh' }}>
                {/*<Header />*/}
                <form name="order_info" className="pad" action="https://mobilepay.l-and.shop/kcp_api_pay"
                      method="POST">
                    <p>Order Information</p>

                    {/*<label htmlFor="name">Name</label>*/}
                    <input type="text" id="name" name="buyr_name" required onChange={(e) => {
                        setMemberName(e.target.value)
                    }} value={member_name} placeholder="Name"/>

                    {/*<label htmlFor="phone">Phone Number</label>*/}
                    <input type="tel" id="phone" name="buyr_tel2" required onChange={(e) => {
                        setMemberPhone(e.target.value)
                    }} value={member_phone} placeholder="Phone Number"/>

                    {/*<label htmlFor="email">Email</label>*/}
                    <input type="email" id="email" name="buyr_mail" required onChange={(e) => {
                        setMemberEmail(e.target.value)
                    }} value={member_email} placeholder="Email"/>

                    {/*<label htmlFor="address">Address</label>*/}

                    <Address setCountry={setCountry}
                             setRegion={setRegion}
                             setCity={setCity}
                             setDeliveryFee={setDeliveryFee}
                             country={country}
                             quantity={state.quantity}
                             isDomestic={isDomestic}
                    />
                    <input type="text" id="address" name="address" required onChange={(e) => {
                        setMemberAddress(e.target.value)
                    }} value={member_address} placeholder="Other addresses"/>
                    <input id={"postCode"} value={postCode} placeholder={'Post Code'} onChange={(e)=> {setPostCode(e.target.value)}} />

                    <div className={"price_container"}>
                        <label className={"price_label"} htmlFor="price">Product Price</label>
                        <input type="text" id="price" name="price_view" readOnly={true} required
                               value={!isDomestic ? `$ ${state.amount.toLocaleString()}` : `₩ ${state.amount.toLocaleString()}`} placeholder="price"/>
                        <input type="hidden" id="price" name="price" readOnly={true} required
                               value={state.amount} placeholder="price"/> {/* 금액 */}

                        <label className={"price_label"} htmlFor="price">Delivery Fee</label>
                        <input type="text" id="price" name="price" readOnly={true} required
                               value={!isDomestic ? `$ ${delivery_fee.toLocaleString()}` : `₩ ${delivery_fee.toLocaleString()}`} placeholder="price"/> {/* 배송비 */}
                        <input type="hidden" id="price" name="price" readOnly={true} required
                               value={delivery_fee} placeholder="price"/> {/* 배송비 */}

                        <label className={"price_label totalPrice"} htmlFor="price">Total Price</label>

                            <b>{!isDomestic ? `USD` : `KRW`}</b>
                            <input className={'totalPrice'} type="text" id="price" name="price" readOnly={true} required value={!isDomestic ? `$ ${(state.amount+delivery_fee).toLocaleString()}` : `₩ ${(state.amount+delivery_fee).toLocaleString()}`} placeholder="price"/> {/* 총 금액 */}

                        <input className={'totalPrice'} type="hidden" id="price" name="price" readOnly={true} required value={state.amount+delivery_fee} placeholder="price"/> {/* 총 금액 */}
                    </div>


                    <ul className="checkbox-list">
                        <li>
                            <label htmlFor="age" className="checkbox-label">
                                <input
                                    type="checkbox"
                                    id="age"
                                    name="years_age"
                                    className="checkbox-input"
                                    required
                                    onChange={(e) => {
                                        if (e.currentTarget.checked) {
                                            setAge(true);
                                        } else {
                                            setAge(false);
                                        }
                                    }}
                                />
                                <span>I am 19 years of age or older. (Required)</span>
                            </label>
                        </li>
                        <li>
                            <label htmlFor="terms" className="checkbox-label">
                                <input
                                    type="checkbox"
                                    id="terms"
                                    name="terms_of_use"
                                    className="checkbox-input"
                                    required
                                    checked={isTerms}
                                    onChange={(e) => {
                                        if (e.currentTarget.checked) {
                                            openModal('terms');
                                        }
                                    }}
                                />
                                <span>Terms and Conditions of Use (Required)</span>
                                <button
                                    className={'viewButton'}
                                    onClick={() => {
                                        openModal('terms');
                                    }}
                                    type="button"
                                >
                                    Review Terms
                                </button>
                            </label>
                        </li>
                        <li>
                            <label htmlFor="privacypolicy" className="checkbox-label">
                                <input
                                    type="checkbox"
                                    id="privacypolicy"
                                    name="privacy_policy"
                                    className="checkbox-input"
                                    required
                                    checked={isPrivacy}
                                    onChange={(e) => {
                                        if (e.currentTarget.checked) {
                                            openModal('Privacy');
                                        }
                                    }}
                                />
                                <span>Privacy Policy (Required)</span>
                                <button
                                    className={'viewButton buttonLeft'}
                                    type="button"
                                    onClick={() => {
                                        openModal('Privacy');
                                    }}
                                >
                                    Review Terms
                                </button>
                            </label>
                        </li>
                    </ul>
                    <div className="wrap-btn">
                        <button type="reset" onClick={() => {
                            handleBack();
                        }}>Cancel
                        </button>
                        <button type="button" onClick={() => {
                            kcpPayModule();
                        }}>Send
                        </button>
                    </div>
                    {/*가맹점 정보 설정*/}
                    <input type="hidden" name="site_cd" value={`${!isDomestic ? 'AKVEE' : 'AKVDW'}`}/>
                    {/*<input type="hidden" name="site_cd" value={'AKVDW'}/>*/}
                    <input type="hidden" name="site_name" value="퍼지펭귄"/>
                    <input type="hidden" name="quotaopt" value="12"/>
                    <input type="hidden" name="res_cd" value=""/>
                    <input type="hidden" name="res_msg" value=""/>
                    <input type="hidden" name="enc_info" value=""/>
                    <input type="hidden" name="enc_data" value=""/>
                    <input type="hidden" name="tran_cd" value=""/>
                    <input type="hidden" name="currency" value={`${!isDomestic ? 'USD' : 'WON'}`}/>
                    {/*<input type="hidden" name="currency" value={'WON'}/>*/}
                    <input type="hidden" name="ordr_idxx" value={orderid} maxLength={40}/>
                    <input type="hidden" name="good_name" value={state?.title}/>
                    {/*<input type="hidden" name="good_mny" value={sum(state.amount, delivery_fee, country)}/>*/}
                    <input type="hidden" name="good_mny"
                           value={!isDomestic ? `${state.amount + delivery_fee}00` : state.amount + delivery_fee}/>
                    {/*<input type="hidden" name="good_mny" value={state.amount}/>*/}
                    <input type="hidden" name="pay_method" value="100000000000"/> {/* 결제수단 */}
                    <input type="hidden" name="site_key"
                           value={`${isDomestic ? '4imPgl0-yLEcJ2vwP.S7AtY__' : '0.5xTjKLQFn9cShRte1zguS__'}`}/>
                    {/*<input type="hidden" name="site_key"*/}
                    {/*   value={'0.5xTjKLQFn9cShRte1zguS__'}/>*/}
                    <input type="hidden" name="pay_type" value="PACA"/>
                    <input type="hidden" name="eng_flag" value={!isDomestic ? 'Y' : ''}/>
                    <input type="hidden" name="used_card_YN" value="Y"/>
                    <input type="hidden" name="used_card" value={ !isDomestic ? "CCXA:CCXB:CCXC:CCUF" : "CCDI:CCLG:CCSS:CCNH:CCKM:CCWR:CCLO:CCHN:CCKE:CCXA:CCXB:CCXC:CCUF:CCBC"}/>
                    {/*<input type="hidden" name="used_card"*/}
                    {/*       value="CCDI:CCLG:CCSS:CCNH:CCKM:CCWR:CCLO:CCHN:CCKECCXA:CCXB:CCXC:CCUF:CCBC"/>*/}
                    <input type="hidden" name="param_opt_2" value={tprSeq}/>
                    <input type="hidden" name="param_opt_3" value={fullURL}/>

                    <input type="hidden" name="product_price" value={state.amount}/>
                    <input type="hidden" name="delivery_fee" value={delivery_fee}/>
                    <input type="hidden" name="country" value={country}/>
                </form>
            </div>


                <Modal
                    isOpen={isModalOpen}
                    onRequestClose={closeModal} // 배경 클릭이나 ESC 키로 모달 닫기
                    contentLabel="Modal"
                    style={{
                        overlay: {
                            overflow: 'hidden'
                        },
                        content: {
                            top: "50%",
                            left: "50%",
                            right: "auto",
                            bottom: "auto",
                            marginRight: "-50%",
                            transform: "translate(-50%, -50%)",
                            padding: "20px",
                            borderRadius: "10px",
                            height: '60%',
                            zIndex: '1',
                            background: 'rgba(30, 30, 30, 0.8)'
                        },
                    }}
                >
                    {type === 'terms' ? <Terms closeModal={closeModal} setChecked={setTerms} /> : <Privacy closeModal={closeModal} setChecked={setPrivacy}/>}
                </Modal>
            </div>
        </>
    );
};

export default Payment;

// 빈 export 구문 추가
export {};
