import React, {useState, useEffect, useContext, useRef, useCallback} from 'react';
import HeaderSub from '../components/HeaderSub';
import Header from '../components/HeaderHidden';
import '../App.css';
import 'swiper/css';
import 'swiper/css/pagination';
import {Pagination} from 'swiper/modules';
import {Swiper, SwiperSlide} from 'swiper/react';
import {useNavigate, useParams} from 'react-router-dom';
import {ApiContext} from '../App';
import Section05 from "../components/Section05";
import {useWallet} from "@aptos-labs/wallet-adapter-react";
import {WalletSelector} from "../web3/WalletSelector";

interface ProductData {
    seqNo: number;
    productName: string;
    category: string;
    description: string;
    content: string;
    botContent: string;
    retailPrice: number;
    soldPrice: number;
    soldUsdPrice: number;
    productStock: number;
    imageFilePath: string;
    subImg1Path?: string;
    subImg2Path?: string;
    subImg3Path?: string;
    subImg4Path?: string;
    subImg5Path?: string;
}

// window 객체 확장을 위해 선언
declare global {
    interface Window {
        KCP_Pay_Execute_Web?: (form: HTMLFormElement) => void;
    }
}

const ProductBuy = () => {
    const stroageSeqNo = localStorage.getItem('seqNo');

    const apiUrl = useContext(ApiContext);
    const {seqNo} = useParams<{ seqNo: string }>();
    const [productData, setProductData] = useState<ProductData | null>(null);
    const [quantity, setQuantity] = useState(1);
    const [totalPrice, setTotalPrice] = useState(0);
    const containerRef = useRef<HTMLDivElement>(null);
    const navigate = useNavigate();
    const {account} = useWallet();
    const [tprSeq, setTprSeq] = useState('');
    const [isPC, setIsPc] = useState(false);
    const [lastScrollY, setLastScrollY] = useState(0); // 마지막 스크롤 위치

    const [isWalletOpen, setIsWalletOpen] = useState(false);

    const [isWalletSelectorOpen, setIsWalletSelectorOpen] = useState(false);
    const [location, setLocation] = useState(null); // 위치 정보
    const [isDomestic, setIsDomestic] = useState(false); // 국내 여부

    const [isScrollingDown, setIsScrollingDown] = useState(false);

    function isMobile() {
        const userAgent = navigator.userAgent || navigator.vendor;
        // 모바일 장치 키워드 확인
        return /android|iphone|ipad|ipod|blackberry|windows phone|iemobile/i.test(userAgent);
    }

    useEffect(() => {
        // 위치 정보 가져오기
        const fetchLocation = async () => {
            try {
                const userLanguage = navigator.languages ? navigator.languages[0] : navigator.language;
                if (userLanguage.startsWith("ko")) {
                    console.log('KR!!!!!!!!');
                    setIsDomestic(true);
                } else {
                    console.log('NO!!!!!!!!');
                    setIsDomestic(false);
                }
            } catch (error) {
                console.error("위치 정보를 가져오는 데 실패했습니다:", error);
            }
        };

        fetchLocation();
    }, []);

    useEffect(() => {
        // KCP 스크립트 동적 로드
        const script = document.createElement("script");
        script.src = "https://testspay.kcp.co.kr/plugin/kcp_spay_hub.js";
        script.type = "text/javascript";
        document.body.appendChild(script);

        return () => {
            // 스크립트 제거
            document.body.removeChild(script);
        };
    }, []);

    useEffect(() => {
        const fetchProductData = async () => {
            try {
                const response = await fetch(`${apiUrl}/api/product/detail/${seqNo}`);
                const result = await response.json();

                console.log(result);
                if (result.code === 200) {
                    setProductData(result.data);
                    setTotalPrice(!isDomestic ? result.data.soldUsdPrice : result.data.soldPrice);
                } else {
                    console.error('Error fetching product:', result.message);
                }
            } catch (error) {
                console.error('Failed to fetch product data:', error);
            }
        };

        // 2초마다 데이터 갱신
        const intervalId = setInterval(() => {
            fetchProductData();
        }, 1000);

        return () => clearInterval(intervalId);
    }, [seqNo, apiUrl]);

    useEffect(() => {
        if (productData) {
            setTotalPrice(!isDomestic ? productData.soldUsdPrice * quantity : productData.soldPrice * quantity);
        }
    }, [quantity, productData]);

    const increment = () => {
        if (quantity >= 15) {
            alert('The maximum number is limited to 15.');
            return false;
        }

        // @ts-ignore
        if (quantity >= productData?.productStock) {
            alert('You cannot exceed the available stock.');
            return false;
        }

        setQuantity(quantity + 1);
    };
    const decrement = () => quantity > 1 && setQuantity(quantity - 1);

    const handleWheel = (event: React.WheelEvent) => {

        // event.target을 HTMLElement로 타입 캐스팅
        const targetElement = event.target as HTMLElement;

        // .box.height 영역 내에서 스크롤을 막음
        if (targetElement.closest('.box.height')) {
            event.preventDefault(); // 해당 영역에서의 스크롤을 막음
            return;
        }

        if (containerRef.current) {
            containerRef.current.scrollTop += event.deltaY;
        }

        // @ts-ignore
        if (event.deltaY === "-100" || event.deltaY === "-50") {
            setIsScrollingDown(false);
        } else { // @ts-ignore
            if (event.deltaY === "100" || event.deltaY === "50") {
                setIsScrollingDown(true);
            }
        }
    };

    const mobileScroll = useCallback(() => {
        const currentScrollY = window.scrollY;

        if (currentScrollY > lastScrollY && currentScrollY > 50) {
            setIsScrollingDown(true);
        } else {
            setIsScrollingDown(false);
        }

        setLastScrollY(currentScrollY); // 스크롤 위치 갱신
    }, [lastScrollY]);

    useEffect(() => {
        window.addEventListener("scroll", mobileScroll);

        return () => {
            window.removeEventListener("scroll", mobileScroll);
        };
    }, [mobileScroll]);


    const closeWalletSelector = () => {
        setIsWalletSelectorOpen(false); // WalletSelector 닫기
    };

    const movePayForm = (title: string, amount: number, product_seq: string, quantity: number) => {
        const address = account?.address;

        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        const raw = JSON.stringify({
            "productSeq": product_seq,
            "quantity": quantity,
            "walletAddr": account?.address || '',
        });

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow"
        }
        if (!address) {
            if (window.confirm("You can purchase after logging in.")) {
                setIsWalletSelectorOpen(true); // 확인 클릭 시 WalletSelector 활성화
            } else {
                return false; // 취소 클릭 시 아무 작업도 하지 않음
            }
            return false;
        } else {
            // @ts-ignore
            fetch(`${apiUrl}/api/payment/temporarySave`, requestOptions)
                .then((response) => response.text())
                .then((result) => {
                    console.log('result22:', result);
                    const res = JSON.parse(result);
                    // setTprSeq(res.res.seqNo);
                    if (res.res.status === '0000') {
                        //모바일과 pc 구분
                        const res = JSON.parse(result);

                        if (address) {
                            navigate('/pages/Payment', {
                                // props로 받는 list 스테이트를 넘겨준다.
                                state: {title: title, amount: amount, product_seq: product_seq, quantity: quantity},
                            })
                        }

                        localStorage.setItem('seqNo', res.res.seqNo);
                        console.log('result22:', res);
                    } else if (res.res.status === '0001') {
                        alert("재고수량이 없습니다.");
                        window.location.reload();
                        return false;
                    } else if (res.res.status === 'fail') {
                        alert("Wallets are not allowed on this product. Please contact customer service.");
                        return false;
                    }

                })
                .catch((error) => console.error(error));
        }
    };

    if (!productData) {
        return <div>Loading...</div>;
    }

    return (

        <div
            className="store-view"
            ref={containerRef}
            style={{
                // overflowY: 'auto'
                height: '100vh'
            }}
            onWheel={handleWheel} // onWheel 이벤트 핸들러 설정
        >
            <Header isHidden={isScrollingDown}/>
            <section id="store_view">
                <div className="merch-section">
                    <div className="container">
                        <div className="w51 fl mg0">
                            <div className="small_img3">
                                <img src="/image/left_ice.png" className="fl" alt="left ice"/>
                                <img src="/image/right_ice.png" className="fl" alt="right ice"/>
                            </div>
                            <div className="big_img_area">
                                <Swiper
                                    pagination={{clickable: true}}
                                    modules={[Pagination]}
                                    className="mySwiper"
                                >
                                    {[productData.imageFilePath, productData.subImg1Path, productData.subImg2Path, productData.subImg3Path, productData.subImg4Path, productData.subImg5Path]
                                        .filter((img) => img)
                                        .map((img, index) => (
                                            <SwiperSlide key={index}>
                                                <img src={img} className="big_img" alt={`product ${index + 1}`}
                                                     style={{
                                                         backgroundColor: '#fff',
                                                         padding: '15px',
                                                         border: 'solid 3px',
                                                         boxShadow: '15px 15px 0px #588AFF, rgba(0, 0, 0) 12px 12px 11px, rgba(0, 0, 0) 18px 18px 0px '

                                                     }}
                                                />

                                            </SwiperSlide>
                                        ))}
                                </Swiper>
                            </div>
                        </div>

                        <div className="w50 fr width">
                            <div className="box fontS" style={{fontSize: "60px"}}>
                                <div dangerouslySetInnerHTML={{__html: productData.productName}}/>
                            </div>
                            <div className="box height">
                                <div dangerouslySetInnerHTML={{__html: productData.content}}/>
                            </div>
                            <div className="box mgTop">
                                <div className="price_box">
                                    <div className="price fr">

                                        <span>{!isDomestic ? totalPrice : totalPrice.toLocaleString()} {!isDomestic ? 'USD' : 'KRW'}</span>
                                    </div>
                                    <div className="packNum fl">
                                        <button onClick={decrement} className="hoverable" id="decrement"
                                                style={{backgroundColor: '#fff', border: 0}}
                                        >
                                            <img src="/image/minus.png" alt="decrement"/>
                                        </button>
                                        <span>{quantity}</span>
                                        <button onClick={increment} className="hoverable" id="increment"
                                                style={{backgroundColor: '#fff', border: 0}}
                                        >
                                            <img src="/image/plus.png" alt="increment"/>
                                        </button>
                                    </div>
                                </div>
                                {productData.productStock <= 0 ? (
                                    <button className="exchain_btn">
                                        <img src="/image/sold_out.png" alt="Sold Out"/>
                                    </button>
                                ) : (
                                    <button className="exchain_btn" onClick={() => {
                                        if (seqNo != null) {
                                            movePayForm(productData.productName, totalPrice, seqNo, quantity);
                                        }
                                    }}>
                                        <img src="/image/exchain_btn.png" alt="Exchange"/>
                                    </button>

                                )}
                                {isWalletSelectorOpen && (
                                    <WalletSelector isModalOpen={isWalletSelectorOpen}
                                                    setModalOpen={closeWalletSelector}/>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className={'resize-container'} dangerouslySetInnerHTML={{__html: productData.botContent}}/>
                </div>
            </section>

            <Section05/>
        </div>
    );
};

export default ProductBuy;
