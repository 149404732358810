// eslint-disable-next-line no-unused-vars
"use client";
import React, {useState, useEffect, useContext} from 'react';
import {useWeb3React} from "@web3-react/core";
import ConnectWalletWithGoogle from "../web3/ConnectWalletWithGoogle";
import {WalletSelector} from "../web3/WalletSelector";
import AppleLogin from 'react-apple-login';
import {jwtDecode} from "jwt-decode";
import {InjectedConnector} from "@web3-react/injected-connector";
import {useWallet} from '@aptos-labs/wallet-adapter-react';
import PurchaseHistory from "../pages/PurchaseHistory";
import Modal from "react-modal";
import {useNavigate, useLocation} from "react-router-dom";
import SocialList from './SocialList';
import {ApiContext} from "../App";
import {useFullPageContext} from "../FullPageContext";

const injected = new InjectedConnector({supportedChainIds: [1, 3, 4, 5, 42]});

interface ConnectWalletProps {
    isOpen: boolean;
    onClose: () => void;
    address: string | null | undefined;
}

interface HistoryProps {
    isOpen: boolean;
    onClose: () => void;
}

// interface headerProps {
//     goToSection: (index: number) => void;
// }

const Header: React.FC = () => {
    const apiUrl = useContext(ApiContext);
    const navigate = useNavigate();
    const location = useLocation(); // 현재 URL 정보를 가져옴
    const [isWalletOpen, setIsWalletOpen] = useState(false);
    const [isAppleSDKLoaded, setIsAppleSDKLoaded] = useState(false);
    const [isHistoryOpen, setIsHistoryOpen] = useState(false);
    const [isSocialOpen, setIsSocialOpen] = useState(false);
    const {goToSection} = useFullPageContext(); // goToSection 가져오기
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 780);

    useEffect(() => {
        const handleResize = () => {
            const isNowMobile = window.innerWidth <= 780;

            if (isNowMobile !== isMobile) {
                // 모바일/PC 상태가 변경될 때 API 호출
                setIsMobile(isNowMobile);
            }
        };

        window.addEventListener("resize", handleResize);

        // cleanup
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, [isMobile]);

    const openWallet = () => setIsWalletOpen(true);
    // const closeWallet = () => setIsWalletOpen(false);
    const closeWallet = () => {

        const currentPath = window.location.pathname;
        console.log('currentPath :', currentPath);
        // 루트와 ProductDetail이 포함된 경로를 제외한 경우 메인 페이지로 이동
        if (
            currentPath !== '/' ||
            !currentPath.includes('ProductBuy') ||
            !currentPath.includes('ProductDetail') // 'ProductDetail'을 포함하지 않는 경우
        ) {
            navigate('/'); // 메인 페이지로 이동
        }

        setIsWalletOpen(false); // 지갑 닫기

    };
    const openHistory = () => setIsHistoryOpen(true);
    const closeHistory = () => setIsHistoryOpen(false);

    //social
    const socialOpen = () => setIsSocialOpen(true);
    const closeSocial = () => setIsSocialOpen(false);

    const tokenValue = localStorage.getItem('account');
    const [token, setToken] = useState(false);

    const {connect, disconnect, account, connected, wallets} = useWallet();

    useEffect(() => {
        console.log("dev 개발서버");
        if (tokenValue === null) {
            setToken(false);
        } else {
            setToken(true);
        }
    }, [tokenValue]);

    useEffect(() => {
        if (!account?.address) {
            console.log("No account found");
        }
        localStorage.setItem('account', account?.address as string);
    }, [account]);

    // useEffect(() => {
    //     const checkAppleSDK = () => {
    //         if (window.AppleID) {
    //             setIsAppleSDKLoaded(true);
    //         } else {
    //             const script = document.createElement('script');
    //             script.src = 'https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js';
    //             script.onload = () => setIsAppleSDKLoaded(true);
    //             script.onerror = () => console.error('Apple JS SDK 로드 실패');
    //             document.body.appendChild(script);
    //         }
    //     };
    //
    //     checkAppleSDK();
    // }, []);

    const [visibleSlide, setVisibleSlide] = useState<boolean>(true);

    useEffect(() => {
        const titleButton = document.querySelector(".wallet-button span");

        // titleButton이 존재하면 텍스트를 제거
        if (titleButton) {
            titleButton.textContent = "";
        }

    }, []);

    useEffect(() => {
        const fetchSlides = async () => {
            try {
                const response = await fetch(`${apiUrl}/api/notice/list`);
                const result = await response.json();

                console.log('result :', result);
                if (result.code === 200) {

                    if (result.data.length > 0) {
                        setVisibleSlide(true);
                    } else {
                        setVisibleSlide(false);
                    }
                } else {
                    console.error('Error fetching slides:', result.message);
                    setVisibleSlide(false);
                }
            } catch (error) {
                console.error('Failed to fetch slides:', error);
                setVisibleSlide(false);
            }
        };

        fetchSlides();
    }, [apiUrl]);

    const ToSection = (index: number, section: string) => {
        if (window.location.href.includes('pages')) {
            sessionStorage.setItem('s_index', `${index}`); // 'key'에 'value' 값을 담음
            navigate("/");
        } else {
            goToSection(index)
        }
    };

    return (
        <div>
            <header>
                <div className="logo">
                    <a href="/">
                        <img src="/image/pudgy_logo3.png" alt="Logo"/>
                    </a>
                </div>
                <div className="gnb">
                    <ul>
                        {isMobile ?

                            <>
                                <li><a href="javascript: void(0);" onClick={() => ToSection(0, 'prologue')}>PROLOGUE</a></li>
                                {/*<li><a href="javascript: void(0);" onClick={() => ToSection(2, 'land')}>L.AND</a></li>*/}
                                <li><a className={!visibleSlide ? 'visibleFalse' : ``} href="javascript: void(0);"
                                       onClick={() => ToSection(1, 'shop')}>SHOP</a></li>
                            </>
                            :
                            <>
                                <li><a href="javascript: void(0);" onClick={() => ToSection(0, 'prologue')}>PROLOGUE</a></li>
                                {/*<li><a href="javascript: void(0);" onClick={() => ToSection(2, 'land')}>L.AND</a></li>*/}
                                <li><a className={!visibleSlide ? 'visibleFalse' : ``} href="javascript: void(0);"
                                       onClick={() => ToSection(1, 'shop')}>SHOP</a></li>
                            </>

                        }

                        {/*<li><a href="javascript: void(0);" onClick={() => ToSection(1, 'prologue')}>PROLOGUE</a></li>*/}
                        {/*<li><a href="javascript: void(0);" onClick={() => ToSection(2, 'land')}>L.AND</a></li>*/}
                        {/*<li><a className={!visibleSlide ? 'visibleFalse' : ``} href="javascript: void(0);"*/}
                        {/*       onClick={() => ToSection(3, 'shop')}>SHOP</a></li>*/}
                        <li><a href="javascript: void(0);" onClick={() => ToSection(2, 'land')}>L.AND</a></li>
                        {/*<li><a href="javascript: void(0);" onClick={() => ToSection(4, 'project')}>PROJECT</a></li>*/}
                    </ul>
                </div>
                {account?.address ? (
                    <div className="logout">
                        <button type="button" onClick={socialOpen} className="X-Social"></button>
                        <button type="button" onClick={() => {
                            navigate('/pages/PaymentDetails');
                        }} className="mypage"></button>
                        <button type="button" onClick={openWallet} className="logoutBtn"></button>
                    </div>
                ) : (
                    <div className="login">
                        <button type="button" onClick={socialOpen} className="X-Social"></button>
                        <WalletSelector/>
                    </div>
                )}

                <SocialList isModalOpen={isSocialOpen} closeModal={closeSocial}/>
                <ConnectWallet isOpen={isWalletOpen} onClose={closeWallet} address={account?.address}/>
            </header>
        </div>
    );
}


const ConnectWallet: React.FC<ConnectWalletProps> = ({isOpen, onClose, address}) => {

    const {disconnect} = useWallet();

    const handleDisconnect = () => {
        disconnect(); // disconnect 함수 호출로 지갑 연결 해제
        localStorage.removeItem('MetaMask');
        onClose();
    };

    return (
        <div className="header-modal-overlay">
            {isOpen && (
                <>
                    <div onClick={onClose}></div>

                    <div className="eventModal " tabIndex={-1} role="dialog" aria-modal="true">
                        <div className="modal-dialog modal-dialog-centered" role="document">
                            <div className="modal-content min-h-auto bg_white posi-r"
                                 style={{border: '3px solid #00142D'}}>
                                <button onClick={onClose} className="modal-close-btn2 w-ba logoutClose">
                                    <img src="/image/close.png" alt="Close"/>
                                </button>
                                <div className="wallbody loginPage logoutPage">
                                    <div className="modal-body">
                                        <center><img src="/image/logoutText.png" alt="Connect Wallet"/></center>
                                        <p className="wall_ment outText">
                                            Are you sure you want to log out?
                                        </p>
                                        <p className="wall_ment w_address">
                                            <span>{address}</span>
                                        </p>
                                        <div className="wrap-btn outBtn">
                                            <button onClick={onClose}>Cancel</button>
                                            <button onClick={handleDisconnect}>Confirm</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </>

            )}
        </div>
    )
};

interface JwtPayload {
    sub: string;
    name: string;
    iat: number;
}

const AppleLoginButton = () => {
    const [userInfo, setUserInfo] = useState<any>(null);
    const [authToken, setAuthToken] = useState<any>(null);
    const {chainId, account, active, activate, deactivate,} = useWeb3React();
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [tokenValue, setTokenValue] = useState<string | null>('');
    const storedAccount = localStorage.getItem('account');
    const handleCallback = async (data: any) => {
        const {authorization, user} = data;
        if (!active) {
            try {
                activate(injected);
                // window.location.reload(); // 화면 새로고침
                console.log('Wallet activated:', active);
            } catch (error) {
                console.error('Error activating wallet:', error);
            }
        }


        if (account) {
            localStorage.setItem('account', account);
        }

        if (authorization) {
            const {code, id_token} = authorization;
            console.log("Authorization Code:", code);
            console.log("ID Token:", id_token);

            setIsLoggedIn(true);
            localStorage.setItem('isLoggedIn', 'true');

            // ID Token 디코딩
            const decodedToken = jwtDecode(id_token);
            console.log("Decoded Token:", decodedToken);

            // Apple 고유 사용자 ID, 이메일 등 정보 추출
            const appleUserId = decodedToken?.sub;
            const email = decodedToken.nbf;

            localStorage.setItem('appleIdToken', id_token);

            if (typeof appleUserId === "string") {
                localStorage.setItem('appleUserId', appleUserId);
            }

            // localStorage.setItem('appleUserEmail', email);

            setAuthToken(id_token); // 상태 업데이트
            // setUserInfo({ userId: appleUserId, email }); // 상태 업데이트

        } else {
            console.error("Authorization 정보 없음");
        }
    };


    // 컴포넌트가 처음 렌더링 될 때, 로컬스토리지에서 유저 정보 불러오기
    useEffect(() => {
        const storedLoginStatus = localStorage.getItem('isLoggedIn');
        const storedToken = localStorage.getItem('appleIdToken');
        const storedUserId = localStorage.getItem('apple_user_id');
        const storedAccount = localStorage.getItem('account');

        setTokenValue(storedAccount);

        if (storedLoginStatus === 'true') {
            setIsLoggedIn(true);
        }
        setAuthToken(storedToken);
    }, []);

    useEffect(() => {
        if (active && account) {
            localStorage.setItem('account', account);
        }
    }, [active, account]);


    const handleLogout = () => {
        setAuthToken(""); // 상태 초기화
        localStorage.setItem('isLoggedIn', 'false');
        localStorage.removeItem('appleIdToken');
        localStorage.removeItem('account');
        localStorage.removeItem('active');
        localStorage.removeItem('MetaMask');
        window.location.reload(); // 화면 새로고침
        console.log("Logged out");
    };

    return (
        <div className={`apple-login-container`}
        >
            {/* AppleLogin 컴포넌트를 authToken이 없을 때만 표시 */}
            {!isLoggedIn ? (
                <AppleLogin
                    clientId="com.pudgy.loginIdentifier"
                    redirectURI="https://l-and.shop/"
                    scope="name email"
                    responseType="code id_token"
                    usePopup={true}
                    callback={handleCallback}
                />) : null}
            {authToken ? (
                <div>
                    <p style={{fontSize: "13px"}}>wallet : {tokenValue}</p>
                    <button
                        onClick={handleLogout}
                        style={{
                            padding: '10px 20px',
                            fontSize: '12px',
                            backgroundColor: '#4285F4',
                            color: 'white',
                            border: 'none',
                            borderRadius: '5px',
                            cursor: 'pointer',
                        }}
                    >
                        Logout
                    </button>
                </div>
            ) : null}
        </div>
    )
}

export default Header;
